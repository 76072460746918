export function getConfig(env = "dev") {
  const isProd = env === "prod";

  const config = {
    domain: isProd
      ? process.env.REACT_APP_AUTH0_DOMAIN_PROD
      : process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: isProd
      ? process.env.REACT_APP_AUTH0_CLIENT_ID_PROD
      : process.env.REACT_APP_AUTH0_CLIENT_ID,
    apiOrigin: isProd
      ? process.env.REACT_APP_API_ORIGIN_PROD
      : process.env.REACT_APP_API_ORIGIN,
    audience: isProd
      ? process.env.REACT_APP_AUTH0_AUDIENCE_PROD
      : process.env.REACT_APP_AUTH0_AUDIENCE,
  };

  return config;
}

const config = getConfig();

export default config;
