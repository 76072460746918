// BreadcrumbsContext.js
import React, { createContext, useState, useContext, useEffect } from "react";

const BreadcrumbsContext = createContext();

export const useBreadcrumbs = () => useContext(BreadcrumbsContext);

export const BreadcrumbsProvider = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = useState(() => {
    const storedBreadcrumbs = sessionStorage.getItem("breadcrumbs");
    return storedBreadcrumbs ? JSON.parse(storedBreadcrumbs) : [];
  });

  useEffect(() => {
    sessionStorage.setItem("breadcrumbs", JSON.stringify(breadcrumbs));
  }, [breadcrumbs]);

  return (
    <BreadcrumbsContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>
      {children}
    </BreadcrumbsContext.Provider>
  );
};
