import React, { useEffect, useRef } from "react";
import { jwtDecode } from "jwt-decode";
import logo from "./assets/white_qurrent_icon.png";
import PropTypes from "prop-types";

const GoogleAuth = ({ onLoginSuccess, onLoginFailure }) => {
  const googleButtonRef = useRef(null);
  useEffect(() => {
    const initializeGoogleSignIn = () => {
      if (
        window.google &&
        window.google.accounts &&
        window.google.accounts.id
      ) {
        window.google.accounts.id.initialize({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          callback: handleCredentialResponse,
        });

        window.google.accounts.id.renderButton(googleButtonRef.current, {
          theme: "standard",
          size: "large",
          type: "standard",
          shape: "rectangular",
          text: "continue_with",
          logo_alignment: "center",
          width: 200,
        });
      } else {
        console.error("Google Identity Services not loaded");
      }
    };

    // Check if the Google Identity Services script is already loaded
    if (window.google && window.google.accounts) {
      initializeGoogleSignIn();
    } else {
      // If not loaded, add the script dynamically
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.onload = initializeGoogleSignIn;
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    }

    // Cleanup function
    return () => {
      if (
        window.google &&
        window.google.accounts &&
        window.google.accounts.id
      ) {
        window.google.accounts.id.cancel();
      }
      // Remove or define isInitialized if needed
      // isInitialized = false;
    };
  }, []);

  const handleCredentialResponse = (response) => {
    if (response.error) {
      console.error("Credential Response Error:", response.error);
      onLoginFailure(`Error retrieving a token: ${response.error}`);
      return;
    }

    const userObject = jwtDecode(response.credential);
    const domain = userObject.email.split("@")[1];

    if (isDomainWhitelisted(domain)) {
      sessionStorage.setItem("user", JSON.stringify(userObject)); // Store user info
      sessionStorage.setItem("googleIdToken", response.credential); // Store token
      onLoginSuccess(userObject);
    } else {
      onLoginFailure("Domain not authorized");
    }
  };

  const isDomainWhitelisted = (domain) => {
    const whitelistedDomains = ["qurrent.ai"];
    return whitelistedDomains.includes(domain);
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <img src={logo} alt="Company Logo" className="company-logo" />
        <h1 className="welcome-message">Welcome to Qurrent!</h1>
        <p className="login-instruction">
          Please sign in with your Qurrent account
        </p>
        <div ref={googleButtonRef}></div>
      </div>
    </div>
  );
};

GoogleAuth.propTypes = {
  onLoginSuccess: PropTypes.func.isRequired,
  onLoginFailure: PropTypes.func.isRequired,
};

export default GoogleAuth;
