import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./App.css";
import qurrentLogoDark from "./assets/qurrent_icon.png";

const NotAuthorized = () => {
  const navigate = useNavigate();
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const darkModePreference = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;
    setIsDarkMode(darkModePreference);
    navigate("/not-authorized", { replace: true });
  }, [navigate]);

  const handleBackToMain = () => {
    navigate("/");
  };

  return (
    <div className="not-authorized-container">
      <img
        src={qurrentLogoDark}
        alt="Qurrent Logo"
        className="qurrent-logo"
        style={{ width: "200px", height: "200px", marginBottom: "20px" }}
      />
      <h1>Not Authorized</h1>
      <p>You are not authorized to access this workflow.</p>
      <button onClick={handleBackToMain} className="back-to-main-button">
        Back to Main Page
      </button>
    </div>
  );
};

export default NotAuthorized;
