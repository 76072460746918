import React, { useEffect, useContext, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Auth0Auth from "./Auth0Auth.js";
import MainContent from "./MainContent.js";
import WorkflowDetails from "./WorkflowDetails.js";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import AutoLogout from "./AutoLogout.js";
import { EnvironmentContext } from "./contexts/EnvironmentContext.js";
import { UserProvider, useUser } from "./contexts/UserContext.js";
import { getConfig } from "./config.js";
import NotAuthorized from "./NotAuthorized.js";

function App() {
  const {
    isLoading,
    isAuthenticated,
    user,
    error,
    getAccessTokenSilently,
    logout,
  } = useAuth0();

  const { env } = useContext(EnvironmentContext);
  const config = getConfig(env);

  const { setUserIsQurrent } = useUser();
  const [userIsQurrent, setUserIsQurrentState] = useState(false);

  const applySavedTheme = () => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme === "dark") {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  };

  useEffect(() => {
    applySavedTheme();
  }, []);

  useEffect(() => {
    const handleUserLogin = async () => {
      if (isAuthenticated && user) {
        try {
          const token = await getAccessTokenSilently({
            audience: config.audience,
          });
          const loginResponse = await axios.post(
            `${config.apiOrigin}/users/login`,
            {
              email: user.email,
              name: user.name || user.email.split("@")[0],
              ip_address: window.location.hostname,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
              },
            }
          );

          if (loginResponse.data.success) {
            console.log(
              "USER IS QURRENT: ",
              loginResponse.data.user_is_qurrent
            );
            sessionStorage.setItem("session_id", loginResponse.data.session_id);
            setUserIsQurrent(loginResponse.data.user_is_qurrent);
            setUserIsQurrentState(loginResponse.data.user_is_qurrent);
          }
        } catch (error) {
          console.error("Error during login or session logging:", error);
          if (error.response) {
            if (error.response.status === 403) {
              console.log("Domain not authorized");
              console.log("Error message:", error.message);
              alert(
                "Your email was not authorized. Please use your work email. If the problem persists, contact team@qurrent.ai"
              );
            } else if (error.response.status === 500) {
              console.log("Error message:", error.message);
              alert(
                "Internal server error occurred. If the problem persists, contact team@qurrent.ai"
              );
            } else {
              console.log("Error message:", error.message);
              alert(
                "Unknown error occurred. If the problem persists, contact team@qurrent.ai"
              );
            }
          } else {
            console.log("Error message:", error.message);
            alert(
              "Unknown error occurred. If the problem persists, contact team@qurrent.ai"
            );
          }

          const auth0Domain = config.auth0Domain;

          config.logout({
            returnTo: window.location.origin,
            federated: true,
            end_session_endpoint: `https://${auth0Domain}/v2/logout?federated`,
          });
        }
      } else {
        console.log("Auth0 state:", {
          isLoading,
          isAuthenticated,
          user: user ? { ...user, email: user.email } : null,
          error,
        });
        console.log(
          "User is not authenticated or user object is not available"
        );
      }
    };

    handleUserLogin();
  }, [isAuthenticated, user, getAccessTokenSilently, isLoading, error, config]);

  if (error) {
    alert(
      `An error occurred: ${error.message}. Please try again or contact support if the issue persists.`
    );

    const auth0Domain = config.auth0Domain;

    logout({
      returnTo: window.location.origin,
      federated: true,
      end_session_endpoint: `https://${auth0Domain}/v2/logout?federated`,
    });
  }

  if (isLoading) {
    return (
      <div className="error-container-middle">
        <div className="error-message">Loading...</div>
      </div>
    );
  }

  return (
    <UserProvider>
      <Router>
        <div className="App">
          <AutoLogout />
          {isAuthenticated ? (
            <Routes>
              <Route
                path="/"
                element={
                  <MainContent user={user} userIsQurrent={userIsQurrent} />
                }
              />
              <Route
                path="/workflow/:workflowId/:workflowInstanceId"
                element={<WorkflowDetails userIsQurrent={userIsQurrent} />}
              />
              <Route path="/not-authorized" element={<NotAuthorized />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          ) : (
            <Routes>
              <Route path="/login" element={<Auth0Auth />} />
              <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
          )}
        </div>
      </Router>
    </UserProvider>
  );
}

export default App;
