// src/components/Cards.js
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark,
  faExclamationTriangle,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";

function Cards({
  totalWorkflows,
  totalRuns,
  errorCount,
  warningCount,
  successCount,
  toggleStatusFilter,
  isExpanded,
  currentDate,
  error,
  fetchWorkflowInstances,
}) {
  return (
    <div className="App-cards">
      <div className="First-Three">
        <div className="App-card App-card-first">
          <div className="row">
            <span className="number">{totalWorkflows}</span>
            <span className="text">Active Workflows</span>
          </div>
          <div className="separator"></div>
          <div className="row">
            <span className={typeof totalRuns === "number" ? "number" : "text"}>
              {totalRuns !== null ? totalRuns : "Loading..."}
            </span>
            {typeof totalRuns === "number" && (
              <span className="text">Runs</span>
            )}
          </div>
        </div>
        <div className="App-card App-card-second">
          <div className="icon" onClick={() => toggleStatusFilter("failed")}>
            <FontAwesomeIcon
              icon={faCircleXmark}
              className="status-icon-card-failed"
            />
          </div>
          <div className="text" onClick={() => toggleStatusFilter("failed")}>
            Failed
          </div>
          <div
            className="number failed"
            onClick={() => toggleStatusFilter("failed")}
          >
            {errorCount}
          </div>
          <div className="icon" onClick={() => toggleStatusFilter("error")}>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="status-icon-card-error"
            />
          </div>
          <div className="text" onClick={() => toggleStatusFilter("error")}>
            Warning
          </div>
          <div className="number error">{warningCount}</div>
          <div className="icon" onClick={() => toggleStatusFilter("completed")}>
            <FontAwesomeIcon
              icon={faCircleCheck}
              className="status-icon-card-success"
            />
          </div>
          <div className="text" onClick={() => toggleStatusFilter("completed")}>
            Success
          </div>
          <div
            className="number completed"
            onClick={() => toggleStatusFilter("completed")}
          >
            {successCount}
          </div>
        </div>
      </div>
      <div
        className={`App-card App-card-fourth ${isExpanded ? "expanded" : ""}`}
      >
        <h4>
          Supervisor Report
          <span className="report-date-separator">•</span>
          <span className="report-date">{currentDate}</span>
        </h4>
        <div className={`report-content ${isExpanded ? "expanded" : ""}`}>
          <p>
            {
              "Coming Soon - a summary of the activity and status of your workflows for the last 7 days."
            }
          </p>
        </div>
      </div>
      {error && (
        <div className="error-notification">
          <div className="error-content">
            <h2>Oops! Something went wrong</h2>
            <p className="error-message">Please try again later.</p>
            <button className="retry-button" onClick={fetchWorkflowInstances}>
              Try Again
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Cards;
