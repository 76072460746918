// src/components/Pagination.js
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesLeft,
  faChevronLeft,
  faChevronRight,
  faAnglesRight,
} from "@fortawesome/free-solid-svg-icons";

function Pagination({
  currentPage,
  totalPages,
  pageInput,
  handlePageInputChange,
  handlePageInputSubmit,
  goToFirstPage,
  goToPreviousPage,
  goToNextPage,
  goToLastPage,
  disableNext,
}) {
  return (
    <div className="App-pagination">
      <button
        onClick={goToFirstPage}
        disabled={currentPage === 1}
        className="pagination-arrow"
      >
        <FontAwesomeIcon icon={faAnglesLeft} />
      </button>
      <button
        onClick={goToPreviousPage}
        disabled={currentPage === 1}
        className="pagination-arrow"
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </button>
      <span>
        Page{" "}
        <input
          type="text"
          value={pageInput}
          onChange={handlePageInputChange}
          onKeyDown={(e) => e.key === "Enter" && handlePageInputSubmit()}
          className="page-input"
          onFocus={(e) => (e.target.style.borderColor = "#007BFF")}
          onBlur={(e) => {
            handlePageInputSubmit();
            e.target.style.borderColor = "#ccc";
          }}
        />{" "}
        of {totalPages}
      </span>
      <button
        onClick={goToNextPage}
        disabled={disableNext}
        className="pagination-arrow"
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
      <button
        onClick={goToLastPage}
        disabled={currentPage === totalPages}
        className="pagination-arrow"
      >
        <FontAwesomeIcon icon={faAnglesRight} />
      </button>
    </div>
  );
}

export default Pagination;
