import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const WorkflowRow = ({
  workflow,
  isLast,
  selectedRunId,
  childWorkflows,
  handleWorkflowClick,
  handleRunClick,
  toggleExpand,
  getStatusIcon,
  expandedRunIds,
  lastElementRef,
  handleSeeMoreClickError,
}) => {
  const shouldShowWarning =
    (workflow.status === "completed" ||
      workflow.status === "created" ||
      workflow.status === "Success") &&
    Array.isArray(workflow.error) &&
    workflow.error.length > 0 &&
    workflow.error[0] !== "N/A";

  return (
    <React.Fragment key={workflow.id}>
      <tr
        ref={isLast ? lastElementRef : null}
        onClick={(event) => {
          if (workflow.parent_workflow_instance_id) {
            handleWorkflowClick(workflow.workflow_id, workflow);
          } else {
            handleRunClick(event, workflow);
          }
        }}
        className={selectedRunId === workflow.id ? "selected-run" : ""}
        style={{ cursor: "pointer" }}
      >
        <td>
          {childWorkflows[workflow.id] && (
            <FontAwesomeIcon
              icon={
                expandedRunIds.has(workflow.id) ? faChevronDown : faChevronRight
              }
              className="dropdown-icon"
              onClick={(e) => {
                e.stopPropagation();
                toggleExpand(workflow.id);
              }}
            />
          )}
        </td>
        <td>{workflow.formattedStartTime}</td>
        <td>{workflow.formattedEndTime}</td>
        <td>
          {getStatusIcon(workflow.status, workflow.error)}{" "}
          {shouldShowWarning
            ? "Warning"
            : workflow.status === "failed"
            ? "Failed"
            : workflow.status}
        </td>
        <td>{workflow.outputDetails}</td>
        <td>
          {workflow.status === "failed" ? (
            <span className="light-opacity">N/A</span>
          ) : Array.isArray(workflow.error) && workflow.error.length > 0 ? (
            <>
              {workflow.error[0].substring(0, 20)}...{" "}
              <button
                onClick={() => handleSeeMoreClickError(workflow.error)}
                className="see-more-button"
              >
                see more
              </button>
            </>
          ) : typeof workflow.error === "string" && workflow.error !== "N/A" ? (
            <>
              {workflow.error.substring(0, 20)}...{" "}
              <button
                onClick={() => handleSeeMoreClickError(workflow.error)}
                className="see-more-button"
              >
                see more
              </button>
            </>
          ) : (
            <span className="light-opacity">N/A</span>
          )}
        </td>
      </tr>
      {expandedRunIds.has(workflow.id) &&
        childWorkflows[workflow.id] &&
        childWorkflows[workflow.id].map((child) => (
          <WorkflowRow
            key={child.id}
            workflow={child}
            isLast={false}
            selectedRunId={selectedRunId}
            childWorkflows={childWorkflows}
            handleWorkflowClick={handleWorkflowClick}
            handleRunClick={handleRunClick}
            toggleExpand={toggleExpand}
            getStatusIcon={getStatusIcon}
            expandedRunIds={expandedRunIds}
            lastElementRef={lastElementRef}
            handleSeeMoreClickError={handleSeeMoreClickError}
          />
        ))}
    </React.Fragment>
  );
};

export default WorkflowRow;
