import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App.js";
import { Auth0Provider } from "@auth0/auth0-react";
import { getConfig } from "./config.js";
import history from "./utils/history.js";
import { ReactFlowProvider } from "reactflow";
import { SuperdupontProvider } from "./contexts/SuperdupontContext.js";
import { BreadcrumbsProvider } from "./contexts/BreadcrumbsContext.js";
import { EnvironmentProvider } from "./contexts/EnvironmentContext.js";
import { FilterProvider } from "./contexts/FilterContext.js";
import { ObservableViewProvider } from "./contexts/ObservableViewContext.js";
import { UserProvider } from "./contexts/UserContext.js";

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null),
  },
};

const root = createRoot(document.getElementById("root"));
root.render(
  <Auth0Provider {...providerConfig}>
    <React.StrictMode>
      <EnvironmentProvider>
        <UserProvider>
          <SuperdupontProvider>
            <ObservableViewProvider>
              <FilterProvider>
                <BreadcrumbsProvider>
                  <ReactFlowProvider>
                    <App />
                  </ReactFlowProvider>
                </BreadcrumbsProvider>
              </FilterProvider>
            </ObservableViewProvider>
          </SuperdupontProvider>
        </UserProvider>
      </EnvironmentProvider>
    </React.StrictMode>
  </Auth0Provider>
);
