/* eslint-disable no-unused-vars */
import successIcon from "./assets/success_icon.svg";
import errorIcon from "./assets/error_icon.svg";
import sort_icon from "./assets/sort_order_icon.svg";
import iconLeft from "./assets/icon_left.svg";
import iconRight from "./assets/icon_right.svg";
import Header from "./Header";

import ai_report_icon from "./assets/ai_report_icon.png";
import filter_lines from "./assets/filter_lines.png";
import Calendar from "react-calendar";

import React, { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios"; // Make sure to install axios: npm install axios

function MainContent() {
  const navigate = useNavigate();
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [date, setDate] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const submitButtonRef = useRef(null);
  const calendarRef = useRef(null);
  const [filters, setFilters] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const [tableData, setTableData] = useState([]);

  const [isExpanded, setIsExpanded] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [userMessages, setUserMessages] = useState([]);
  const [aiResponses, setAiResponses] = useState([]);

  const [errorCount, setErrorCount] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  const [noStatusCount, setNoStatusCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [totalWorkflows, setTotalWorkflows] = useState(0);
  const [totalRuns, setTotalRuns] = useState(0);
  const [bearerToken] = useState(localStorage.getItem("bearerToken") || "");

  const [reportContent] = useState(
    "The schedule screening workflow failed 3 out of 5 times in the past 7 days. All three times there was a response that looked like this and did this to the workflow. This is additional content This is additional content This is additional content This is additional content This is additional content This is additional content This is additional content This is additional content This is additional content that will be hidden initially."
  );

  const [currentDate] = useState(
    new Date().toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    })
  );

  const scrollableContentRef = useRef(null);

  const [isLoading, setIsLoading] = useState(true); // Declare isLoading state
  const [isRefreshing] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchWorkflowInstances();
    // Set up an interval to fetch workflow instances every 30 seconds
    const intervalId = setInterval(fetchWorkflowInstances, 30000);
    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    console.log("Table data updated:", tableData);
  }, [tableData]);

  useEffect(() => {
    localStorage.setItem("workflowTableData", JSON.stringify(tableData));
  }, [tableData]);

  const formatDateTime = (dateString) => {
    if (!dateString || dateString === "N/A") return "N/A";
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return "Invalid Date";

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${ampm}`;
  };

  const updateStatusCounts = useCallback(() => {
    const counts = tableData.reduce((acc, item) => {
      acc[item.status] = (acc[item.status] || 0) + 1;
      return acc;
    }, {});

    setErrorCount(counts["error"] || 0);
    setSuccessCount(counts["success"] || 0);
    setNoStatusCount(counts["no-status"] || 0);
  }, [tableData]);

  useEffect(() => {
    if (bearerToken) {
      fetchWorkflowInstances();
      const intervalId = setInterval(fetchWorkflowInstances, 30000);
      return () => clearInterval(intervalId);
    }
  }, [bearerToken, currentPage]);

  const fetchWorkflowInstances = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const idToken = sessionStorage.getItem("googleIdToken");

      const response = await axios.get(`${apiUrl}/filter/runs/grouped`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });

      console.log("Fetched workflow instances:", response.data);
      const newData = {};

      if (Array.isArray(response.data.data)) {
        response.data.data.forEach((workflow) => {
          let frontendStatus;
          switch (workflow.status) {
            case "created":
              frontendStatus = "no-status";
              break;
            case "failed":
              frontendStatus = "error";
              break;
            case "completed":
              frontendStatus = "success";
              break;
            default:
              frontendStatus = "no-status";
              console.log("Unrecognized status:", workflow.status);
          }

          const lastRunDate = new Date(workflow.last_run);

          newData[workflow.name] = {
            id: workflow.id,
            status: frontendStatus,
            runs: workflow.runs,
            failed: workflow.failed,
            name: workflow.name,
            version: workflow.version,
            description: workflow.description || "N/A",
            lastRun: formatDateTime(lastRunDate),
            lastRunTimestamp: lastRunDate, // Store original date object
            trigger: "External",
          };
        });
      }

      const newTableData = Object.values(newData);
      setTableData(newTableData);
      updateStatusCounts();

      setTotalWorkflows(newTableData.length);
      setTotalRuns(
        newTableData.reduce((sum, workflow) => sum + workflow.runs, 0)
      );
      setErrorCount(
        newTableData.reduce((sum, workflow) => sum + workflow.failed, 0)
      );

      if (response.data.total) {
        setPageCount(Math.ceil(response.data.total / itemsPerPage));
      }
    } catch (err) {
      console.error("Error fetching workflow instances:", err);
      setError("Failed to load workflow data. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  }, [itemsPerPage]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    // Scroll to bottom after expanding
    if (!isExpanded) {
      setTimeout(() => {
        if (scrollableContentRef.current) {
          scrollableContentRef.current.scrollTop =
            scrollableContentRef.current.scrollHeight;
        }
      }, 0);
    }
  };

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && userInput.trim() !== "") {
      e.preventDefault(); // Prevent default Enter behavior
      setUserMessages([...userMessages, userInput.trim()]);

      // Add automated response
      const automatedResponse =
        "This is a hardcoded AI response. You can replace this with any text you want later.";
      setAiResponses([...aiResponses, automatedResponse]);

      setUserInput("");

      // Scroll to bottom after state update
      setTimeout(() => {
        if (scrollableContentRef.current) {
          scrollableContentRef.current.scrollTop =
            scrollableContentRef.current.scrollHeight;
        }
      }, 0);
    }
  };

  const handleSort = (column) => {
    if (column === "description" || column === "trigger") {
      return; // Do nothing for description and trigger columns
    }

    if (column === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }

    const sortedData = sortData(
      tableData,
      column,
      sortDirection === "asc" ? "desc" : "asc"
    );
    setTableData(sortedData);
  };

  const sortData = (data, column, direction) => {
    return [...data].sort((a, b) => {
      let aValue, bValue;

      if (column === "status") {
        const statusOrder = { success: 0, error: 1, "no-status": 2 };
        aValue = statusOrder[a.status];
        bValue = statusOrder[b.status];
      } else if (column === "lastRun") {
        aValue = a.lastRunTimestamp.getTime(); // Use original date object
        bValue = b.lastRunTimestamp.getTime(); // Use original date object
      } else if (typeof a[column] === "string") {
        aValue = a[column].toLowerCase();
        bValue = b[column].toLowerCase();
      } else {
        aValue = a[column];
        bValue = b[column];
      }

      if (aValue < bValue) return direction === "asc" ? -1 : 1;
      if (aValue > bValue) return direction === "asc" ? 1 : -1;
      return 0;
    });
  };

  const getSortIcon = (column) => {
    if (column === sortColumn) {
      return (
        <img
          src={sort_icon}
          alt="Sort"
          style={{
            width: "14px",
            height: "14px",
            transform: sortDirection === "asc" ? "rotate(180deg)" : "none",
            transition: "transform 0.3s ease",
            marginLeft: "5px",
          }}
        />
      );
    }
    return (
      <img
        src={sort_icon}
        alt="Sort"
        style={{
          width: "14px",
          height: "14px",
          opacity: 0.3,
          marginLeft: "5px",
        }}
      />
    );
  };

  const handleChange = (value) => {
    setDate(value);
    setSelectedDateRange(value);
    setShowCalendar(true);
  };

  const submitDateRange = () => {
    if (selectedDateRange && selectedDateRange.length === 2) {
      const [start, end] = selectedDateRange;
      const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear().toString().slice(-2);
        return `${day}-${month}-${year}`;
      };
      const dateRangeFilter = {
        type: "dateRange",
        value: `${formatDate(start)} to ${formatDate(end)}`,
        start: start,
        end: end,
      };

      setFilters((prevFilters) => {
        const dateRangeIndex = prevFilters.findIndex(
          (f) => f.type === "dateRange"
        );
        if (dateRangeIndex !== -1) {
          return prevFilters.map((f, i) =>
            i === dateRangeIndex ? dateRangeFilter : f
          );
        } else {
          return [...prevFilters, dateRangeFilter];
        }
      });

      setShowCalendar(false);
      setSelectedDateRange(null);
    }
  };

  const toggleFilterOptions = () => {
    setShowFilterOptions(!showFilterOptions);
  };

  const handleFilterOption = (option) => {
    console.log(option);
    if (option === "date") {
      handleChange();
    } else {
      addFilter(option);
    }
    setShowFilterOptions(true);
  };

  const addFilter = (filter) => {
    setActiveFilters([...activeFilters, filter]);
  };

  const removeFilter = (index) => {
    setFilters((prevFilters) => prevFilters.filter((_, i) => i !== index));
  };

  const addStatusFilter = (status) => {
    console.log("Adding status filter:", status);
    const newFilter = { type: "status", value: status };
    setFilters((prevFilters) => {
      const statusFilterIndex = prevFilters.findIndex(
        (filter) => filter.type === "status"
      );
      if (statusFilterIndex !== -1) {
        // If status filter exists, replace it
        return prevFilters.map((filter, index) =>
          index === statusFilterIndex ? newFilter : filter
        );
      } else {
        // If status filter doesn't exist, add it
        return [...prevFilters, newFilter];
      }
    });
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter" && submitButtonRef.current) {
        submitButtonRef.current.click();
      }
    };

    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    }

    // Only add the event listener if the calendar is shown
    if (showCalendar) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showCalendar]);

  const filteredData = React.useMemo(() => {
    return tableData.filter((item) => {
      return filters.every((filter) => {
        if (filter.type === "status") {
          if (filter.value.toLowerCase() === "no status") {
            return item.status === "no-status";
          }
          return item.status === filter.value.toLowerCase();
        }
        if (filter.type === "dateRange") {
          const itemDate = new Date(item.lastRunTimestamp);
          const startDate = new Date(filter.start);
          startDate.setHours(0, 0, 0, 0); // Set to start of day
          const endDate = new Date(filter.end);
          endDate.setHours(23, 59, 59, 999); // Set to end of day
          return itemDate >= startDate && itemDate <= endDate;
        }
        return true;
      });
    });
  }, [tableData, filters]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, pageCount));
  };

  const handleRowClick = (item) => {
    navigate(`/workflow/${item.id}`, {
      state: { name: item.name, description: item.description },
    });
  };

  useEffect(() => {
    updateStatusCounts();
  }, [tableData, updateStatusCounts]);

  const handleDateFilter = (option) => {
    if (option === "Custom") {
      handleFilterOption("date");
    } else {
      const end = new Date();
      end.setHours(23, 59, 59, 999); // Set to end of day

      let start = new Date(end);
      start.setHours(0, 0, 0, 0); // Set to start of day

      if (option === "Last 7 days") {
        start.setDate(end.getDate() - 6);
      } else if (option === "Last 30 days") {
        start.setDate(end.getDate() - 29);
      }

      const dateRangeFilter = {
        type: "dateRange",
        value: option,
        start: start,
        end: end,
      };

      setFilters((prevFilters) => {
        const dateRangeIndex = prevFilters.findIndex(
          (f) => f.type === "dateRange"
        );
        if (dateRangeIndex !== -1) {
          return prevFilters.map((f, i) =>
            i === dateRangeIndex ? dateRangeFilter : f
          );
        } else {
          return [...prevFilters, dateRangeFilter];
        }
      });
    }
    setShowFilterOptions(false);
  };

  return (
    <main className="App-main">
      <Header />
      <div className="App-cards">
        <div className="First-Three">
          <div className="App-card App-card-first">
            <div className="row">
              <span className="number">{totalWorkflows}</span>
              <span className="text">Active Workflows</span>
            </div>
            <div className="separator"></div>
            <div className="row">
              <span className="number">{totalRuns.toLocaleString()}</span>
              <span className="text">Runs</span>
            </div>
          </div>

          <div className="App-card App-card-second">
            <div className="icon">
              <img src={errorIcon} alt="Error" />
            </div>
            <div className="text" onClick={() => addStatusFilter("Error")}>
              Error
            </div>
            <div
              className="number error"
              onClick={() => addStatusFilter("Error")}
            >
              {errorCount}
            </div>
            <div className="icon">
              <img src={successIcon} alt="Success" />
            </div>
            <div className="text" onClick={() => addStatusFilter("Success")}>
              Success
            </div>
            <div
              className="number success"
              onClick={() => addStatusFilter("Success")}
            >
              {successCount}
            </div>
            <span className="status-icon no-status">—</span>
            <div className="text" onClick={() => addStatusFilter("No Status")}>
              No Status
            </div>
            <div
              className="number"
              onClick={() => addStatusFilter("No Status")}
            >
              {noStatusCount}
            </div>
          </div>
        </div>
        <div
          className={`App-card App-card-fourth ${isExpanded ? "expanded" : ""}`}
          onClick={toggleExpand}
        >
          <div className="scrollable-content" ref={scrollableContentRef}>
            <h4>
              <img src={ai_report_icon} alt="AI Report Icon" />
              AI Supervisor Report
              <span className="report-date-separator">•</span>
              <span className="report-date">{currentDate}</span>
            </h4>
            <div className={`report-content ${isExpanded ? "expanded" : ""}`}>
              <p>{reportContent}</p>
            </div>
            {/* {isExpanded && (
              <div className="user-messages">
                {userMessages.map((message, index) => (
                  <React.Fragment key={index}>
                    <p className="user-message">
                      <span className="message-prefix">&gt;</span>
                      {message}
                    </p>
                    {aiResponses[index] && (
                      <p className="ai-response">
                        <span className="message-prefix">Agent:</span>
                        {aiResponses[index]}
                      </p>
                    )}
                  </React.Fragment>
                ))}
              </div>
            )} */}
          </div>
          {/* {isExpanded && (
            <div
              className="user-input-area"
              onClick={(e) => e.stopPropagation()}
            >
              <textarea
                value={userInput}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                placeholder="Enter your text here..."
              />
            </div>
          )} */}
        </div>
      </div>
      {error && (
        <div className="error-notification">
          <div className="error-content">
            <h2>Oops! Something went wrong</h2>
            <p className="error-message">{error}</p>
            <button className="retry-button" onClick={fetchWorkflowInstances}>
              Try Again
            </button>
          </div>
        </div>
      )}
      <div className="App-filter-row">
        <button className="App-filter-button" onClick={toggleFilterOptions}>
          <div className="App-filter-button-icon">
            <img src={filter_lines} alt="Filter" />
          </div>
          Filter
        </button>
        {activeFilters.map((filter, index) => (
          <div key={index} className="App-active-filter">
            <span dangerouslySetInnerHTML={{ __html: filter }} />
            <span
              className="App-active-filter-remove"
              onClick={() => removeFilter(index)}
            >
              ×
            </span>
          </div>
        ))}
        {filters.map((filter, index) => (
          <div key={index} className="App-filter-tag">
            {filter.type === "dateRange" ? (
              <span dangerouslySetInnerHTML={{ __html: filter.value }} />
            ) : (
              filter.value
            )}
            <button onClick={() => removeFilter(index)}>×</button>
          </div>
        ))}
        <button className="App-add-filter-button" onClick={toggleFilterOptions}>
          {showFilterOptions && (
            <div className="App-filter-options">
              <div className="status-option">
                Status
                <div className="status-submenu">
                  <div onClick={() => addStatusFilter("Error")}>Error</div>
                  <div onClick={() => addStatusFilter("Success")}>Success</div>
                  <div onClick={() => addStatusFilter("No Status")}>
                    No Status
                  </div>
                </div>
              </div>
              <div className="date-option">
                Date
                <div className="date-submenu">
                  <div onClick={() => handleDateFilter("Last 7 days")}>
                    Last 7 days
                  </div>
                  <div onClick={() => handleDateFilter("Last 30 days")}>
                    Last 30 days
                  </div>
                  <div onClick={() => handleDateFilter("Custom")}>
                    Custom date range
                  </div>
                </div>
              </div>
            </div>
          )}
        </button>
        {showCalendar && (
          <div ref={calendarRef} className="App-date-range-picker">
            <Calendar
              onChange={handleChange}
              value={date}
              selectRange={true}
              className="custom-calendar"
            />
            <button
              ref={submitButtonRef}
              className="App-submit-date-range"
              onClick={submitDateRange}
            >
              Submit Date Range
            </button>
          </div>
        )}
      </div>
      <div className="App-table">
        <div className="App-table-header">
          <div className="App-table-row grid-row">
            <div
              className="App-table-cell"
              onClick={() => handleSort("status")}
            >
              Status {getSortIcon("status")}
            </div>
            <div className="App-table-cell" onClick={() => handleSort("runs")}>
              Runs {getSortIcon("runs")}
            </div>
            <div
              className="App-table-cell"
              onClick={() => handleSort("failed")}
            >
              Failed {getSortIcon("failed")}
            </div>
            <div className="App-table-cell" onClick={() => handleSort("name")}>
              Name {getSortIcon("name")}
            </div>
            <div className="App-table-cell">Version</div>
            <div className="App-table-cell description-header">Description</div>
            <div
              className="App-table-cell"
              onClick={() => handleSort("lastRun")}
            >
              Last Run {getSortIcon("lastRun")}
            </div>
            <div className="App-table-cell">Trigger</div>
          </div>
        </div>
        <div className="App-table-body scrollable">
          {currentItems.map((item, index) => (
            <div
              className="App-table-row grid-row"
              key={index}
              onClick={() => handleRowClick(item)}
            >
              <div className="App-table-cell">
                {item.status === "success" && (
                  <img
                    src={successIcon}
                    alt="Success"
                    className="status-icon success"
                  />
                )}
                {item.status === "error" && (
                  <img
                    src={errorIcon}
                    alt="Error"
                    className="status-icon error"
                  />
                )}
                {item.status === "no-status" && (
                  <span className="status-icon no-status">—</span>
                )}
              </div>
              <div className="App-table-cell">{item.runs}</div>
              <div className="App-table-cell">{item.failed}</div>
              <div className="App-table-cell">{item.name}</div>
              <div className="App-table-cell">{item.version}</div>
              <div
                className="App-table-cell description-cell"
                data-full-description={item.description}
              >
                {item.description}
              </div>
              <div className="App-table-cell">{item.lastRun}</div>
              <div className="App-table-cell">{item.trigger}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="App-pagination">
        <button
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
          className="pagination-arrow"
        >
          <img src={iconLeft} alt="Previous" />
        </button>
        <span>
          {currentPage} of {pageCount}
        </span>
        <button
          onClick={goToNextPage}
          disabled={currentPage === pageCount}
          className="pagination-arrow"
        >
          <img src={iconRight} alt="Next" />
        </button>
      </div>
      {isLoading && (
        <div className="loading-spinner-corner">
          <div className="loading-spinner"></div>
        </div>
      )}
      {isRefreshing && (
        <div className="refresh-indicator">
          <div className="loading-spinner"></div>
        </div>
      )}
    </main>
  );
}

export default MainContent;
