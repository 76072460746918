import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSort,
  faCircleXmark,
  faExclamationTriangle,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import runningIcon from "../assets/spinner_icon.png";

export const formatDateTime = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();

  // Convert both dates to UTC to avoid timezone issues
  const utcDate = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
  const utcNow = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());

  // Calculate the difference in days
  const diffDays = Math.floor((utcNow - utcDate) / (1000 * 60 * 60 * 24));
  const diffWeeks = Math.floor(diffDays / 7);

  let displayText;
  if (diffDays === 0) {
    displayText = `Today at ${date.toLocaleTimeString(undefined, {
      hour: "2-digit",
      minute: "2-digit",
    })}`;
  } else if (diffDays < 7) {
    displayText = `${diffDays} day${diffDays > 1 ? "s" : ""} ago`;
  } else if (diffWeeks < 4) {
    displayText = `${diffWeeks} week${diffWeeks > 1 ? "s" : ""} ago`;
  } else {
    displayText = date.toLocaleString(undefined, {
      month: "2-digit",
      day: "2-digit",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  }

  // Full formatted date for tooltip
  const fullFormattedDate = date.toLocaleString(undefined, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });

  return { displayText, fullFormattedDate };
};

export const formatDateRange = (range) => {
  if (!range || !range.start || !range.end) return "Last Run";

  // Parse the date strings into Date objects using local time
  const [startYear, startMonth, startDay] = range.start.split("-").map(Number);
  const [endYear, endMonth, endDay] = range.end.split("-").map(Number);

  const startDate = new Date(startYear, startMonth - 1, startDay);
  const endDate = new Date(endYear, endMonth - 1, endDay);

  // Format the dates for display
  const formattedStartDate = startDate.toLocaleDateString(undefined, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const formattedEndDate = endDate.toLocaleDateString(undefined, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  return `${formattedStartDate} - ${formattedEndDate}`;
};

export const formatDate = (dateString) => {
  const date = new Date(dateString + "Z"); // Append 'Z' to indicate UTC
  if (isNaN(date.getTime())) return "Invalid Date";

  return date.toLocaleString(undefined, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });
};

export const mapStatus = (backendStatus) => {
  switch (backendStatus) {
    case "created":
      return "No Status";
    case "failed":
      return "Failed";
    case "completed":
      return "Success";
    default:
      return backendStatus;
  }
};

export const formatContent = (content) => {
  if (typeof content === "string") {
    try {
      const parsedContent = JSON.parse(content);
      return formatObject(parsedContent);
    } catch (e) {
      return content.replace(/\\n/g, "\n");
    }
  }

  if (Array.isArray(content)) {
    return content.map((item) => formatObject(item)).join("\n\n");
  }

  if (typeof content === "object" && content !== null) {
    return formatObject(content);
  }

  return content;
};

export const getSortIcon = (column, spinningColumn) => {
  return (
    <FontAwesomeIcon
      icon={faSort}
      className={`sort-icon ${spinningColumn === column ? "spinning" : ""}`}
    />
  );
};

export const formatObject = (obj, indent = 0) => {
  const indentation = " ".repeat(indent);
  return Object.entries(obj)
    .map(([key, value]) => {
      if (typeof value === "object" && value !== null) {
        return `${indentation}${key}:\n${formatObject(value, indent + 2)}`;
      }
      return `${indentation}${key}: ${value}`;
    })
    .join("\n");
};

export const formatId = (id) => {
  if (!id || id === "None") return "N/A";
  const displayId = id.slice(-5);
  return `...${displayId}`;
};

export const formatNodeName = (name) => {
  if (!name) return "";
  return name
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const getStatusIcon = (status, error) => {
  // Check if status is failed first
  if (status && status.toLowerCase() === "failed") {
    return (
      <FontAwesomeIcon icon={faCircleXmark} className="status-icon-failed" />
    );
  }

  // Check if error is a non-empty array with meaningful content
  const hasError =
    Array.isArray(error) && error.length > 0 && error[0] !== "N/A";

  if (hasError) {
    return (
      <FontAwesomeIcon
        icon={faExclamationTriangle}
        className="status-icon-error"
      />
    );
  }

  if (!status) {
    return null; // Return null or a default icon if status is undefined
  }

  switch (status.toLowerCase()) {
    case "success":
      return (
        <FontAwesomeIcon icon={faCircleCheck} className="status-icon-success" />
      );
    case "running":
      return <img src={runningIcon} alt="Running" className="status-icon" />;
    default:
      return null;
  }
};

export const getDomainFromEmail = (email) => {
  return email.split("@")[1];
};

export function formatCost(value) {
  if (value === null || value === undefined || isNaN(value)) return "N/A";
  const significantFigures = 2;
  const roundedValue = Number.parseFloat(value).toPrecision(significantFigures);
  return `$${roundedValue}`;
}
