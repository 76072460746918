import dagre from "dagre";
import { MarkerType } from "reactflow";

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

export function toggleNodeVisibility(
  nodeId,
  edges,
  nodes,
  hiddenNodes,
  setHiddenNodes,
  setEdges
) {
  const stack = [];
  const nodesToToggle = new Set();
  const isExpanding = nodes.some(
    (node) =>
      node.id === nodeId &&
      edges.some(
        (edge) => edge.source === nodeId && hiddenNodes.has(edge.target)
      )
  );

  const childEdges = edges.filter((edge) => edge.source === nodeId);
  childEdges.forEach((edge) => {
    const targetNode = nodes.find((n) => n.id === edge.target);
    if (targetNode) {
      stack.push(edge.target);
    }
  });

  let delay = 0;
  let lastObservable = null;

  while (stack.length > 0) {
    const currentNodeId = stack.pop();
    const currentNode = nodes.find((n) => n.id === currentNodeId);

    if (currentNode) {
      if (currentNode.type === "observable") {
        lastObservable = currentNodeId; // Track the last observable
      }

      nodesToToggle.add(currentNodeId);

      const currentChildEdges = edges.filter(
        (edge) => edge.source === currentNodeId
      );
      currentChildEdges.forEach((edge) => {
        const targetNode = nodes.find((n) => n.id === edge.target);
        if (targetNode) {
          stack.push(edge.target);
        }
      });
      // Apply animation with delay
      setTimeout(() => {
        const element = document.getElementById(currentNodeId);
        if (element) {
          element.classList.toggle(
            isExpanding ? "node-slide-in" : "node-slide-out"
          );
        }
      }, delay);
      delay += 100; // Increase delay for each child
    }
  }

  setHiddenNodes((prevHiddenNodes) => {
    const newHiddenNodes = new Set(prevHiddenNodes);
    nodesToToggle.forEach((id) => {
      if (isExpanding) {
        newHiddenNodes.delete(id);
      } else {
        newHiddenNodes.add(id);
      }
    });
    return newHiddenNodes;
  });
}

export const getLayoutedElements = (
  nodes,
  edges,
  nodeWidth,
  nodeHeight,
  hiddenNodes,
  hiddenEdges
) => {
  // Set the graph direction to top-bottom
  dagreGraph.setGraph({ rankdir: "TB" });

  // Define offsets and spacing for node positioning
  const offsetX = 100;
  const offsetY = 50;
  const rootSpacing = 300;
  const subtreeWidthCache = new Map();

  // Initialize node dimensions
  initializeNodeDimensions(nodes, nodeWidth, nodeHeight);

  // Filter out hidden nodes and edges
  const visibleNodes = nodes.filter((node) => !hiddenNodes.has(node.id));
  const visibleEdges = edges.filter((edge) => !hiddenEdges.has(edge.id));

  // Create a function to calculate the width of subtrees
  const getSubtreeWidth = createVisibleSubtreeWidthCalculator(
    visibleEdges,
    nodeWidth,
    offsetX,
    subtreeWidthCache,
    hiddenNodes
  );

  console.log("VISIBLE NODES: ", visibleNodes);
  console.log("VISIBLE EDGES: ", visibleEdges);

  // Combine sequential agents into single nodes
  const combinedRootNodes = combineSequentialAgents(visibleNodes, visibleEdges);

  console.log("COMBINED ROOT NODES: ", combinedRootNodes);

  // Find all descendant nodes of the combined root nodes
  const getDescendantNodes = (rootNodeId) => {
    const descendants = new Set();
    const findDescendants = (parentId) => {
      descendants.add(parentId);
      visibleEdges.forEach((edge) => {
        if (edge.source === parentId && !descendants.has(edge.target)) {
          findDescendants(edge.target);
        }
      });
    };
    findDescendants(rootNodeId);
    return descendants;
  };

  // Include the combined root nodes and all their descendants
  const allRelevantNodeIds = new Set();
  combinedRootNodes.forEach((rootNode) => {
    const descendants = getDescendantNodes(rootNode.id);
    descendants.forEach((id) => allRelevantNodeIds.add(id));
  });

  // Filter the nodes to include only the combined root nodes and their descendants
  const filteredNodes = visibleNodes.filter((node) =>
    allRelevantNodeIds.has(node.id)
  );

  // Filter the edges to include only those that connect the combined root nodes and their descendants
  const filteredEdges = visibleEdges.filter(
    (edge) =>
      allRelevantNodeIds.has(edge.source) && allRelevantNodeIds.has(edge.target)
  );

  // Position root nodes
  positionRootNodes(combinedRootNodes, getSubtreeWidth, rootSpacing);

  // Create functions to find leaf nodes and position child nodes
  const findLeafNodes = createLeafNodeFinder(filteredEdges, hiddenNodes);

  const positionChildren = createChildPositioner(
    filteredNodes,
    filteredEdges,
    offsetX,
    offsetY,
    hiddenNodes
  );

  // Position children for each combined root node
  combinedRootNodes.forEach((rootNode) => {
    positionChildren(rootNode.id, 1, new Set(), 0, rootNode.id);
  });

  // Connect leaf nodes to the next root node
  connectLeafNodesToNextRoot(
    combinedRootNodes,
    findLeafNodes,
    filteredNodes,
    filteredEdges,
    hiddenNodes
  );

  // Add arrowheads to edges
  const edgesWithArrows = addArrowheadsToEdges(filteredEdges);

  // Return the layouted nodes and edges
  return { nodes: filteredNodes, edges: edgesWithArrows };
};

// Function to initialize node dimensions
function initializeNodeDimensions(nodes, nodeWidth, nodeHeight) {
  nodes.forEach((node) => {
    node.width = nodeWidth;
    node.height = nodeHeight;
  });
}

// Function to position root nodes
function positionRootNodes(rootNodes, getSubtreeWidth, rootSpacing) {
  let currentX = 0;
  rootNodes.forEach((rootNode) => {
    const subtreeWidth = getSubtreeWidth(rootNode.id);
    rootNode.position = { x: currentX + 50, y: 0 };
    rootNode.sourcePosition = "bottom";
    rootNode.targetPosition = "left";
    currentX += Math.max(subtreeWidth - 50, rootSpacing);
  });
}

// Function to create a leaf node finder
function createLeafNodeFinder(edges, hiddenNodes) {
  return function findLeafNodes(nodeId, visited = new Set()) {
    if (visited.has(nodeId)) return [];
    visited.add(nodeId);

    const childEdges = edges.filter(
      (edge) => edge.source === nodeId && !hiddenNodes.has(edge.target)
    );

    // If there are no visible child edges, this is a leaf node
    if (childEdges.length === 0) {
      return [nodeId];
    }

    let leafNodes = [];
    childEdges.forEach((edge) => {
      leafNodes = [...leafNodes, ...findLeafNodes(edge.target, visited)];
    });
    return leafNodes;
  };
}

// Function to create a child positioner
function createChildPositioner(nodes, edges, offsetX, offsetY, hiddenNodes) {
  return function positionChildren(
    nodeId,
    depth = 1,
    visited = new Set(),
    yOffset = 0,
    owningRoot
  ) {
    if (visited.has(nodeId)) return yOffset;
    visited.add(nodeId);

    const currentNode = nodes.find((n) => n.id === nodeId);
    if (currentNode) {
      currentNode.data.owningRoot = owningRoot;
    }

    const childEdges = edges.filter((edge) => edge.source === nodeId);
    let currentYOffset = yOffset;

    childEdges.forEach((edge) => {
      const childNode = nodes.find((n) => n.id === edge.target);
      if (childNode) {
        const parentNode = nodes.find((n) => n.id === nodeId);

        // Determine the source position based on hidden nodes
        const isTriggeredByObservable =
          hiddenNodes.has(childNode.id) &&
          nodes.some(
            (node) =>
              hiddenNodes.has(node.id) &&
              node.data?.item?.trigger_observable_id === nodeId
          );

        childNode.position = {
          x: parentNode.position.x + offsetX,
          y: currentYOffset + offsetY,
        };
        childNode.targetPosition = "left";
        childNode.sourcePosition = isTriggeredByObservable ? "right" : "bottom";

        currentYOffset = positionChildren(
          childNode.id,
          depth + 1,
          visited,
          currentYOffset + offsetY,
          owningRoot
        );
      }
    });

    return currentYOffset;
  };
}

// Function to connect leaf nodes to the next root node
function connectLeafNodesToNextRoot(
  rootNodes,
  findLeafNodes,
  nodes,
  edges,
  hiddenNodes
) {
  rootNodes.forEach((rootNode, index) => {
    if (index < rootNodes.length - 1) {
      const nextRootNode = rootNodes[index + 1];
      const leafNodes = findLeafNodes(rootNode.id);

      // Correctly find the last visible leaf node
      const lastVisibleLeafId = leafNodes.findLast(
        (id) => !hiddenNodes.has(id)
      );

      if (lastVisibleLeafId) {
        const lastLeafNode = nodes.find((n) => n.id === lastVisibleLeafId);
        if (lastLeafNode) {
          lastLeafNode.sourcePosition = "right";
          lastLeafNode.isLastNode = true;
          lastLeafNode.data = {
            ...lastLeafNode.data,
            isLastNode: true,
            pointsToRoot: nextRootNode.id,
          };

          edges.push({
            id: `${lastVisibleLeafId}-${nextRootNode.id}`,
            source: lastVisibleLeafId,
            target: nextRootNode.id,
            sourceHandle: "right",
            targetHandle: "left",
          });
        }
      }
    }
  });
}

// Function to add arrowheads to edges
function addArrowheadsToEdges(edges) {
  return edges.map((edge) => ({
    ...edge,
    type: "step",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 15,
      height: 15,
      color: "var(--edge-color-node)",
    },
    style: {
      strokeWidth: 1,
      stroke: "var(--edge-color-node)",
    },
  }));
}

// Function to combine runs with the same ID
export const combineRuns = (runs) => {
  const combinedRuns = {};

  runs.forEach((run) => {
    if (combinedRuns[run.id]) {
      // Combine outputs
      combinedRuns[run.id].output = [
        ...combinedRuns[run.id].output,
        ...(Array.isArray(run.output) ? run.output : [run.output]),
      ];
      // Combine errors, filtering out "N/A" and empty strings
      combinedRuns[run.id].error = [
        ...combinedRuns[run.id].error,
        ...(Array.isArray(run.error) ? run.error : [run.error]).filter(
          (error) => error && error !== "N/A"
        ),
      ];
    } else {
      // Initialize the entry
      combinedRuns[run.id] = {
        ...run,
        output: Array.isArray(run.output) ? run.output : [run.output],
        error: (Array.isArray(run.error) ? run.error : [run.error]).filter(
          (error) => error && error !== "N/A"
        ),
      };
    }
  });

  return Object.values(combinedRuns);
};

// Function to combine sequential agents into single nodes
function combineSequentialAgents(nodes, edges) {
  const combinedNodes = [];
  const newEdges = []; // To store new edges to add after processing
  let lastAgentId = null;
  let lastCombinedNode = null;

  // Use a for loop to iterate over all nodes
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];

    // Skip nodes that are not agents
    if (node.type !== "agent") continue;

    const agentId = node.data?.item?.console_agent?.id;

    // If the agentId matches the previous one, combine nodes
    if (agentId && agentId === lastAgentId && lastCombinedNode) {
      const currentCombinedNode = lastCombinedNode; // Create a block-scoped variable
      const childEdges = edges.filter((edge) => edge.source === node.id);
      childEdges.forEach((edge) => {
        newEdges.push({
          id: `e${currentCombinedNode.id}-${edge.target}`,
          source: currentCombinedNode.id,
          target: edge.target,
        });
      });
    } else {
      if (lastCombinedNode) {
        combinedNodes.push(lastCombinedNode);
      }
      lastAgentId = agentId;
      lastCombinedNode = node;
    }
  }

  // Push the last combined node if any
  if (lastCombinedNode) {
    combinedNodes.push(lastCombinedNode);
  }

  // Append the new edges after the loop to avoid modifying edges during iteration
  edges.push(...newEdges);

  console.log("COMBINED NODES: ", combinedNodes);

  return combinedNodes;
}

function createVisibleSubtreeWidthCalculator(
  edges,
  nodeWidth,
  offsetX,
  subtreeWidthCache,
  hiddenNodes
) {
  return function getVisibleSubtreeWidth(nodeId, visited = new Set()) {
    if (subtreeWidthCache.has(nodeId)) {
      return subtreeWidthCache.get(nodeId);
    }
    if (visited.has(nodeId) || hiddenNodes.has(nodeId)) return 0;
    visited.add(nodeId);

    const childEdges = edges.filter(
      (edge) => edge.source === nodeId && !hiddenNodes.has(edge.target)
    );
    let maxWidth = nodeWidth;

    childEdges.forEach((edge) => {
      const childWidth = getVisibleSubtreeWidth(edge.target, visited);
      maxWidth = Math.max(maxWidth, childWidth + offsetX / 2);
    });

    maxWidth += offsetX;
    subtreeWidthCache.set(nodeId, maxWidth);
    return maxWidth;
  };
}
