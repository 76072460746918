import React, { useContext } from "react";
import { EnvironmentContext } from "./contexts/EnvironmentContext.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExchangeAlt } from "@fortawesome/free-solid-svg-icons";

const EnvSwitcher = ({ onEnvironmentSwitch }) => {
  const { env, switchEnvironment } = useContext(EnvironmentContext);

  const isProd = env === "prod";

  const handleClick = () => {
    switchEnvironment();
    onEnvironmentSwitch();
  };

  return (
    <button
      className={`env-switcher-button ${isProd ? "dev" : "prod"}`}
      onClick={handleClick}
    >
      <FontAwesomeIcon icon={faExchangeAlt} className="env-switcher-icon" />
      {isProd ? (
        <>
          Switch to <span className="dev-text">Development</span>
        </>
      ) : (
        <>
          Switch to <span className="prod-text">Production</span>
        </>
      )}
    </button>
  );
};

export default EnvSwitcher;
