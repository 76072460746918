import React, { createContext, useContext, useState } from "react";

// Create a context for Observable View
const ObservableViewContext = createContext();

// Create a provider component
export const ObservableViewProvider = ({ children }) => {
  const [isObservableViewActive, setIsObservableViewActive] = useState(false);

  const toggleObservableView = () => {
    setIsObservableViewActive((prev) => {
      const newState = !prev;
      sessionStorage.setItem("observableViewMode", JSON.stringify(newState));
      return newState;
    });
  };

  return (
    <ObservableViewContext.Provider
      value={{ isObservableViewActive, toggleObservableView }}
    >
      {children}
    </ObservableViewContext.Provider>
  );
};

// Custom hook to use the Observable View context
export const useObservableView = () => {
  return useContext(ObservableViewContext);
};
