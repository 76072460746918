/* eslint-disable no-unused-vars */
import React, { useState, useRef, useCallback, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import sortOrderIcon from "./assets/sort_order_icon.svg";
import completedIcon from "./assets/success_icon.svg";
import failedIcon from "./assets/error_icon.svg";
import filter_lines from "./assets/filter_lines.png";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import runningIcon from "./assets/spinner_icon.png"; // Add this import
import "./App.css";
import axios from "axios";
import Header from "./Header";
import Xarrow from "react-xarrows";

function WorkflowDetails() {
  const location = useLocation();
  const { name } = location.state || {};
  const [showDescription, setShowDescription] = useState(false);

  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [runs, setRuns] = useState([]);

  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [showStartCalendar, setShowStartCalendar] = useState(false);
  const [showEndCalendar, setShowEndCalendar] = useState(false);
  const submitButtonRef = useRef(null);
  const calendarRef = useRef(null);
  const [filters, setFilters] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);

  const [showAgentMenu, setShowAgentMenu] = useState(false);
  const [showTaskMenu, setShowTaskMenu] = useState(false);

  const { id } = useParams();
  const [workflowDetails, setWorkflowDetails] = useState(null);

  const [workflowStructure, setWorkflowStructure] = useState(null);
  const [connections, setConnections] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [dragPosition, setDragPosition] = useState({ x: 100, y: 200 });

  const [selectedItem, setSelectedItem] = useState(null);
  const popupRef = useRef(null);

  const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:8000";

  const [selectedRun, setSelectedRun] = useState(null);
  const [scale, setScale] = useState(1);
  const [renderKey, setRenderKey] = useState(0);
  const [selectedRunId, setSelectedRunId] = useState(null);

  const resetView = () => {
    setScale(1);
    setDragPosition({ x: 100, y: 200 });
    setRenderKey((prevKey) => prevKey + 1); // Increment key to force re-render
  };

  const handleWheel = (event) => {
    if (event.ctrlKey) {
      // Check if the control key is pressed (indicating a zoom gesture)
      event.preventDefault();
      const zoomFactor = 0.1;
      const newScale = scale + (event.deltaY < 0 ? zoomFactor : -zoomFactor);
      setScale(Math.min(Math.max(newScale, 0.5), 2)); // Limit scale between 0.5 and 2
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setSelectedItem(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onDragStart = useCallback(
    (e) => {
      const startPosition = {
        x: e.clientX - dragPosition.x,
        y: e.clientY - dragPosition.y,
      };

      const onDragMove = (moveEvent) => {
        setDragPosition({
          x: moveEvent.clientX - startPosition.x,
          y: moveEvent.clientY - startPosition.y,
        });
      };

      const onDragEnd = () => {
        document.removeEventListener("mousemove", onDragMove);
        document.removeEventListener("mouseup", onDragEnd);
      };

      document.addEventListener("mousemove", onDragMove);
      document.addEventListener("mouseup", onDragEnd);
    },
    [dragPosition]
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const idToken = sessionStorage.getItem("googleIdToken");
        const headers = {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        };
        const [detailsResponse, instancesResponse] = await Promise.all([
          axios.get(`${apiUrl}/workflows/${id}`, { headers }),
          axios.get(`${apiUrl}/workflows/${id}/instances`, { headers }),
        ]);
        setWorkflowDetails(detailsResponse.data);

        // Process instances data
        const processedInstances = instancesResponse.data.map((instance) => {
          const startTime = new Date(instance.start_time);
          const endTime = instance.end_time
            ? new Date(instance.end_time)
            : null; // Set to null if no end time

          return {
            id: instance.id,
            startTime,
            endTime,
            formattedStartTime: formatDate(startTime), // Use custom format
            formattedEndTime: endTime ? formatDate(endTime) : "N/A", // Display "N/A" if null
            status: mapStatus(instance.status),
            outputDetails: `Actions: ${instance.action_count}, LLM Calls: ${instance.llm_call_count}`,
          };
        });

        setRuns(processedInstances);
        setError(null);
      } catch (err) {
        console.error("Error fetching workflow data:", err);
        setError("Failed to fetch workflow data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, apiUrl]);

  const formatDate = (dateString) => {
    const date = new Date(dateString + "Z"); // Append 'Z' to indicate UTC
    if (isNaN(date.getTime())) return "Invalid Date";

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    let hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert to 12-hour format

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds} ${ampm}`;
  };

  // Add this function to map backend status to frontend status
  const mapStatus = (backendStatus) => {
    switch (backendStatus) {
      case "created":
        return "No Status";
      case "failed":
        return "Error";
      case "completed":
        return "Success";
      default:
        return backendStatus;
    }
  };

  //test1
  useEffect(() => {
    if (workflowStructure) {
      const newConnections = [];
      const calculateConnections = (items, depth = 0) => {
        if (!Array.isArray(items)) {
          console.warn("Expected an array for items, but received:", items);
          return;
        }
        items.forEach((item) => {
          if (item && item.children) {
            item.children.forEach((child) => {
              if (child) {
                newConnections.push({
                  start: { id: item.id, x: 150, y: 30 },
                  end: { id: child.id, x: 0, y: 0 },
                });
              }
            });
            calculateConnections(item.children, depth + 1);
          }
          if (item && item.llm_call) {
            newConnections.push({
              start: { id: item.id, x: 150, y: 30 },
              end: { id: item.llm_call.id, x: 0, y: 0 },
            });
          }
        });
      };

      // Check if workflowStructure is an array, if not, wrap it in an array
      const structureArray = Array.isArray(workflowStructure)
        ? workflowStructure
        : [workflowStructure];

      // Only process if structureArray is not empty
      if (structureArray.length > 0) {
        calculateConnections(structureArray);
        setConnections(newConnections);
      } else {
        console.warn("Workflow structure is empty");
      }
    } else {
      console.warn("Workflow structure is null or undefined");
    }
  }, [workflowStructure]);

  const organizeWorkflowStructure = (data) => {
    // Ensure data is an array and filter out null or undefined items
    const items = Array.isArray(data)
      ? data.filter((item) => item != null)
      : [data].filter((item) => item != null);

    // Sort items by created_at timestamp, handling cases where created_at might be missing
    const sortedItems = items.sort((a, b) => {
      const dateA = a.created_at ? new Date(a.created_at) : new Date(0);
      const dateB = b.created_at ? new Date(b.created_at) : new Date(0);
      return dateA - dateB;
    });

    // Create a map to store items by their ID
    const itemMap = new Map();

    // First pass: create all items
    sortedItems.forEach((item) => {
      itemMap.set(item.id, {
        ...item,
        type: item.function_name === "llm_call" ? "llm_call" : "action",
        children: [],
      });
    });

    // Second pass: build the hierarchy
    const workflowChildren = [];
    sortedItems.forEach((item) => {
      const itemWithChildren = itemMap.get(item.id);
      if (item.trigger_action_id) {
        // If triggered by another action, add as a child to that item
        const parent = itemMap.get(item.trigger_action_id);
        if (parent) {
          parent.children.push(itemWithChildren);
        }
      } else if (item.llm_call_id) {
        // If triggered by an LLM call, add as a child to that LLM call
        const parent = itemMap.get(item.llm_call_id);
        if (parent) {
          parent.children.push(itemWithChildren);
        }
      } else if (item.trigger_workflow_instance_id) {
        // If triggered by workflow, create a new agent container
        const agentContainer = {
          type: "agent",
          id: `${item.agent_name}-${item.id}`, // Ensure unique ID for each agent container
          agent_name: item.agent_name,
          children: [itemWithChildren],
        };
        workflowChildren.push(agentContainer);
      }
    });

    // Create the final structure
    const organizedStructure = {
      type: "workflow",
      id: sortedItems[0]?.trigger_workflow_instance_id || "unknown",
      children: workflowChildren,
    };

    console.log("Organized structure:", organizedStructure);
    return [organizedStructure]; // Wrap in array to maintain expected structure
  };

  const renderWorkflowStructure = () => {
    if (loading) return <div>Loading workflow structure...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!workflowStructure || workflowStructure.length === 0) {
      console.log("workflowStructure is empty or null:", workflowStructure);
      return <div>No workflow structure available</div>;
    }

    const organizedStructure = organizeWorkflowStructure(workflowStructure);

    const renderItem = (item) => {
      const displayId = item.id.slice(-4);

      const handleClick = () => {
        setSelectedItem(item);
      };

      const renderArrows = (parentId, children) => {
        return children.map((child) => (
          <Xarrow
            key={`arrow-${parentId}-${child.id}`}
            start={`${parentId}`}
            end={`${child.id}`}
            color="black"
            strokeWidth={2}
            headSize={6}
            startAnchor="bottom left"
            endAnchor="left" // End at the left middle of the child
            path="smooth" // Use a smooth path for the arrow
            curveness={0.8}
          />
        ));
      };

      switch (item.type) {
        case "action":
          return (
            <div
              className="action-container"
              onClick={handleClick}
              id={item.id}
            >
              Action: {item.function_name}
              {item.children && renderArrows(item.id, item.children)}
            </div>
          );
        case "llm_call":
          return (
            <div
              className="llm-call-container"
              onClick={handleClick}
              id={item.id}
            >
              LLM Call: {displayId}
              {item.children && renderArrows(item.id, item.children)}
            </div>
          );
        case "agent":
          return (
            <div className="agent-container" onClick={handleClick} id={item.id}>
              Agent: {item.agent_name || displayId}
              {item.children && renderArrows(item.id, item.children)}
            </div>
          );
        default:
          return <div>Unknown item type: {item.type}</div>;
      }
    };
    const renderHierarchy = (items) => {
      return items.map((item, index) => (
        <div key={`${item.id}-${index}`} className="workflow-item">
          {renderItem(item)}
          {item.children && item.children.length > 0 && (
            <div>
              <div className="children-container">
                {item.children.map((child, childIndex) => (
                  <div
                    key={`${child.id}-${childIndex}`}
                    className="child-item-wrapper"
                    id={`element-${child.id}`}
                  >
                    {renderItem(child)}
                    <div style={{ marginLeft: "150px" }}>
                      {renderHierarchy(child.children)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ));
    };

    return (
      <div className="workflow-structure">
        <div className="workflow-tree">
          {organizedStructure.map((workflow) => (
            <div key={workflow.id} className="workflow-container">
              {renderHierarchy(workflow.children)}
            </div>
          ))}
        </div>
      </div>
    );
  };
  useEffect(() => {
    const closeMenus = () => {
      setShowAgentMenu(false);
      setShowTaskMenu(false);
    };

    document.addEventListener("click", closeMenus);

    return () => {
      document.removeEventListener("click", closeMenus);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowStartCalendar(false);
        setShowEndCalendar(false);
      }
    }

    // Only add the event listener if the calendar is shown
    if (showStartCalendar || showEndCalendar) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showStartCalendar, showEndCalendar]);

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }

    const sortedRuns = [...runs].sort((a, b) => {
      let compareA, compareB;

      if (column === "startTime" || column === "endTime") {
        compareA = a[column].getTime();
        compareB = b[column].getTime();
      } else if (column === "status") {
        compareA = a[column].toLowerCase();
        compareB = b[column].toLowerCase();
      } else {
        compareA = a[column];
        compareB = b[column];
      }

      if (compareA < compareB) return sortDirection === "asc" ? -1 : 1;
      if (compareA > compareB) return sortDirection === "asc" ? 1 : -1;
      return 0;
    });

    setRuns(sortedRuns);
  };

  const getSortIcon = (column) => {
    if (column === sortColumn) {
      return (
        <img
          src={sortOrderIcon}
          alt="Sort"
          style={{
            cursor: "pointer",
            width: "14px",
            height: "14px",
            transform: sortDirection === "asc" ? "rotate(180deg)" : "none",
            transition: "transform 0.3s ease",
            marginLeft: "5px",
          }}
        />
      );
    }
    return (
      <img
        src={sortOrderIcon}
        alt="Sort"
        style={{
          cursor: "pointer",
          width: "14px",
          height: "14px",
          opacity: 0.3,
          marginLeft: "5px",
        }}
      />
    );
  };

  const getStatusIcon = (status) => {
    switch (status.toLowerCase()) {
      case "success":
        return (
          <img src={completedIcon} alt="Success" className="status-icon" />
        );
      case "error":
        return <img src={failedIcon} alt="Error" className="status-icon" />;
      case "running":
        return (
          <img
            src={runningIcon}
            alt="Running"
            className="status-icon spinning-icon"
          />
        );
      default:
        return null;
    }
  };

  const toggleFilterOptions = () => {
    setShowFilterOptions(!showFilterOptions);
  };

  const handleFilterOption = (option) => {
    if (option === "runStart") {
      setShowStartCalendar(true);
      setShowEndCalendar(false);
    } else if (option === "runEnd") {
      setShowEndCalendar(true);
      setShowStartCalendar(false);
    } else {
      addStatusFilter(option);
    }
    setShowFilterOptions(false);
  };

  const addStatusFilter = (status) => {
    const newFilter = { type: "status", value: status };
    setFilters((prevFilters) => {
      const statusFilterIndex = prevFilters.findIndex(
        (filter) => filter.type === "status"
      );
      if (statusFilterIndex !== -1) {
        return prevFilters.map((filter, index) =>
          index === statusFilterIndex ? newFilter : filter
        );
      } else {
        return [...prevFilters, newFilter];
      }
    });
  };

  const submitDateFilter = (type) => {
    if (dateRange && dateRange[0] && dateRange[1]) {
      const [start, end] = dateRange;
      const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear().toString().slice(-2);
        return `${day}-${month}-${year}`;
      };
      const dateRangeFilter = {
        type: type,
        value: `${formatDate(start)} to ${formatDate(end)}`,
        start: start,
        end: end,
      };

      setFilters((prevFilters) => {
        const dateFilterIndex = prevFilters.findIndex((f) => f.type === type);
        if (dateFilterIndex !== -1) {
          return prevFilters.map((f, i) =>
            i === dateFilterIndex ? dateRangeFilter : f
          );
        } else {
          return [...prevFilters, dateRangeFilter];
        }
      });

      setShowStartCalendar(false);
      setShowEndCalendar(false);
      setDateRange([null, null]);
    }
  };

  const removeFilter = (index) => {
    setFilters((prevFilters) => prevFilters.filter((_, i) => i !== index));
  };

  const filteredRuns = runs.filter((run) => {
    const runStartDate = new Date(run.startTime);
    const runEndDate = new Date(run.endTime);

    return filters.every((filter) => {
      if (filter.type === "status") {
        return run.status.toLowerCase() === filter.value.toLowerCase();
      }
      if (filter.type === "runStart") {
        return runStartDate >= filter.start && runStartDate <= filter.end;
      }
      if (filter.type === "runEnd") {
        return runEndDate >= filter.start && runEndDate <= filter.end;
      }
      return true;
    });
  });

  const fetchWorkflowStructure = async (workflowId, instanceId) => {
    const idToken = sessionStorage.getItem("googleIdToken");

    try {
      const response = await axios.get(
        `${apiUrl}/workflows/${workflowId}/instances/${instanceId}/structure`,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      if (response.status !== 200) {
        throw new Error("Network response was not ok");
      }
      const data = response.data;
      console.log("Received workflow structure:", data);
      return data;
    } catch (error) {
      console.error("Error fetching workflow structure:", error);
      // Handle the error appropriately
    }
  };

  const handleRunClick = (run) => {
    setSelectedRun(run);
    setSelectedRunId(run.id); // Set the selected run ID
    resetView(); // Reset the view position and scale
    fetchWorkflowStructure(id, run.id).then((structureData) => {
      setWorkflowStructure(structureData);
    });
  };

  const formatContent = (content) => {
    if (typeof content === "string") {
      try {
        // Attempt to parse the string as JSON
        const parsedContent = JSON.parse(content);
        return formatObject(parsedContent); // Use custom formatting
      } catch (e) {
        // If parsing fails, replace \n with actual new lines in the string
        return content.replace(/\\n/g, "\n");
      }
    }

    if (Array.isArray(content)) {
      return content.map((item) => formatObject(item)).join("\n\n");
    }

    if (typeof content === "object" && content !== null) {
      return formatObject(content);
    }

    return content;
  };

  const formatObject = (obj, indent = 0) => {
    const indentation = " ".repeat(indent);
    return Object.entries(obj)
      .map(([key, value]) => {
        if (typeof value === "object" && value !== null) {
          return `${indentation}${key}:\n${formatObject(value, indent + 2)}`;
        }
        return `${indentation}${key}: ${value}`;
      })
      .join("\n");
  };

  if (error) return <div>Error: {error}</div>;

  return (
    <div className="WorkflowDetails">
      <Header />
      {loading && <div className="loading-overlay">Loading...</div>}
      <div className="fixed-top">
        <Link to="/" className="back-button">
          ← Back
        </Link>
        {name && (
          <div className="workflow-info">
            <h2>{location.state?.name || "Workflow Details"}</h2>
            <button
              className="description-toggle"
              onClick={() => setShowDescription(!showDescription)}
            >
              ▼
            </button>
            {showDescription && (
              <p className="workflow-description">
                {location.state?.description}
              </p>
            )}
          </div>
        )}
      </div>
      <div
        className="interactive-area"
        style={{ overflow: "auto", height: "calc(100vh - 200px)" }}
        onWheel={handleWheel} // Add wheel event listener
      >
        <div
          key={renderKey} // Use renderKey to force re-render
          style={{
            position: "absolute",
            left: `${dragPosition.x}px`,
            top: `${dragPosition.y}px`,
            cursor: "move",
            transform: `scale(${scale})`, // Apply scale transformation
            transformOrigin: "center", // Set the origin of the transformation
          }}
          onMouseDown={onDragStart}
        >
          {selectedRun && renderWorkflowStructure()}
        </div>
      </div>
      <div className="fixed-bottom">
        <div className="table-container">
          <div className="App-filter-row-workflows">
            {showStartCalendar && (
              <div
                ref={calendarRef}
                className="App-date-range-picker"
                style={{
                  position: "absolute",
                  bottom: "100%",
                  top: "-340px",
                  right: "0",
                  zIndex: 1000,
                  width: "350px",
                }}
              >
                <Calendar
                  onChange={setDateRange}
                  value={dateRange}
                  selectRange={true}
                  className="custom-calendar"
                />
                <button
                  ref={submitButtonRef}
                  className="App-submit-date-range"
                  onClick={() => submitDateFilter("runStart")}
                >
                  Submit Date Range
                </button>
              </div>
            )}
            {showEndCalendar && (
              <div
                ref={calendarRef}
                className="App-date-range-picker"
                style={{
                  position: "absolute",
                  bottom: "100%",
                  top: "-340px",
                  right: "0",
                  zIndex: 1000,
                  width: "350px",
                }}
              >
                <Calendar
                  onChange={setDateRange}
                  value={dateRange}
                  selectRange={true}
                  className="custom-calendar"
                />
                <button
                  ref={submitButtonRef}
                  className="App-submit-date-range"
                  onClick={() => submitDateFilter("runEnd")}
                >
                  Submit Date Range
                </button>
              </div>
            )}
            <button className="App-filter-button" onClick={toggleFilterOptions}>
              <div className="App-filter-button-icon">
                <img src={filter_lines} alt="Filter" />
              </div>
              Filter
            </button>
            {filters.map((filter, index) => (
              <div key={index} className="App-filter-tag">
                {filter.type === "dateRange" ? (
                  <span dangerouslySetInnerHTML={{ __html: filter.value }} />
                ) : (
                  filter.value
                )}
                <button onClick={() => removeFilter(index)}>×</button>
              </div>
            ))}
            <button
              className="App-add-filter-button"
              onClick={toggleFilterOptions}
            >
              {showFilterOptions && (
                <div
                  className="App-filter-options-workflows"
                  style={{ zIndex: 1000 }}
                >
                  <div className="status-option">
                    Status
                    <div className="status-submenu">
                      <div onClick={() => addStatusFilter("Error")}>Error</div>
                      <div onClick={() => addStatusFilter("Success")}>
                        Success
                      </div>
                      <div onClick={() => addStatusFilter("Running")}>
                        Running
                      </div>
                    </div>
                  </div>
                  <div
                    className="App-filter-option"
                    onClick={() => handleFilterOption("runStart")}
                  >
                    Run Start
                  </div>
                  <div
                    className="App-filter-option"
                    onClick={() => handleFilterOption("runEnd")}
                  >
                    Run End
                  </div>
                </div>
              )}
            </button>
            <button
              className="reset-view-button"
              onClick={resetView}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                zIndex: 1000,
              }}
            ></button>
          </div>
          <table className="workflow-table">
            <thead>
              <tr>
                <th onClick={() => handleSort("startTime")}>
                  Run Start {getSortIcon("startTime")}
                </th>
                <th onClick={() => handleSort("endTime")}>
                  Run End {getSortIcon("endTime")}
                </th>
                <th onClick={() => handleSort("status")}>
                  Status {getSortIcon("status")}
                </th>
                <th>Output Details</th>
              </tr>
            </thead>
          </table>
          <div className="table-body-container">
            <table className="workflow-table">
              <tbody>
                {filteredRuns.map((run) => (
                  <tr
                    key={run.id}
                    onClick={() => handleRunClick(run)}
                    className={selectedRunId === run.id ? "selected-run" : ""}
                  >
                    <td>{run.formattedStartTime}</td>
                    <td>{run.formattedEndTime}</td>
                    <td>
                      {getStatusIcon(run.status)} {run.status}
                    </td>
                    <td>{run.outputDetails}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {selectedItem && (
        <div className="popup-overlay">
          <div className="popup-content" ref={popupRef}>
            <h3>
              {selectedItem.type.charAt(0).toUpperCase() +
                selectedItem.type.slice(1)}{" "}
              Details
            </h3>
            {Object.entries(selectedItem)
              .filter(
                ([key]) =>
                  key !== "children" &&
                  key !== "input" &&
                  key !== "output" &&
                  key !== "completion"
              )
              .map(([key, value]) => (
                <p key={key}>
                  <strong>{key}:</strong>{" "}
                  {typeof value === "object"
                    ? JSON.stringify(value, null, 2)
                    : value}
                </p>
              ))}
            {selectedItem.completion && (
              <div className="formatted-content">
                <strong>Completion:</strong>
                <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                  {formatContent(selectedItem.completion)}
                </pre>
              </div>
            )}
            {selectedItem.input && (
              <div className="formatted-content">
                <strong>Input:</strong>
                <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                  {formatContent(selectedItem.input)}
                </pre>
              </div>
            )}
            {selectedItem.output && (
              <div className="formatted-content">
                <strong>Output:</strong>
                <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                  {formatContent(selectedItem.output)}
                </pre>
              </div>
            )}
            <button onClick={() => setSelectedItem(null)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default WorkflowDetails;
