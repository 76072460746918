import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import MainContent from "./mainContent";
import WorkflowDetails from "./workflowDetails";
import GoogleAuth from "./GoogleAuth";
import { BroadcastChannel } from "broadcast-channel"; // Ensure this import is correct
import "./App.css";

function App() {
  const [user, setUser] = useState(null);
  const [lastActivity, setLastActivity] = useState(Date.now());

  // Create a BroadcastChannel
  const channel = new BroadcastChannel("auth-channel");

  useEffect(() => {
    const storedUser = sessionStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }

    const activityHandler = () => setLastActivity(Date.now());
    window.addEventListener("mousemove", activityHandler);
    window.addEventListener("keydown", activityHandler);

    const checkInactivity = setInterval(() => {
      if (Date.now() - lastActivity > 30 * 60 * 1000) {
        handleLogout();
      }
    }, 60000);

    // Listen for logout messages
    channel.onmessage = (event) => {
      if (event.data === "logout") {
        console.log("Received logout message");
        handleLogout();
      }
    };

    return () => {
      window.removeEventListener("mousemove", activityHandler);
      window.removeEventListener("keydown", activityHandler);
      clearInterval(checkInactivity);
      channel.close();
    };
  }, [lastActivity]);

  const handleLoginSuccess = (user) => {
    setUser(user);
    localStorage.setItem("user", JSON.stringify(user));
    // logSessionStart();
  };

  const handleLoginFailure = (message) => {
    console.error("Login failed:", message);
    alert(message);
    sessionStorage.setItem("user", JSON.stringify(user));
  };

  const handleLogout = () => {
    console.log("Logging out");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("googleIdToken");
    setUser(null);
  };

  return (
    <Router>
      <div className="App">
        {user ? (
          <Routes>
            <Route
              path="/"
              element={<MainContent user={user} onLogout={handleLogout} />}
            />
            <Route path="workflow/:id" element={<WorkflowDetails />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        ) : (
          <Routes>
            <Route
              path="/login"
              element={
                <div>
                  <GoogleAuth
                    onLoginSuccess={handleLoginSuccess}
                    onLoginFailure={handleLoginFailure}
                  />
                </div>
              }
            />
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
        )}
      </div>
    </Router>
  );
}

export default App;
