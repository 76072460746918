// supervise-console/src/Breadcrumbs.js
import React from "react";
import { Link } from "react-router-dom";

function Breadcrumbs({
  breadcrumbs,
  currentWorkflowId,
  handleBackButtonClick,
  handleBreadcrumbClick,
}) {
  return (
    <div className="fixed-top">
      <Link to="/" className="back-button" onClick={handleBackButtonClick}>
        Home
        <>&nbsp;</>
      </Link>
      <div className="breadcrumbs">
        {(breadcrumbs || []).map((crumb) => (
          <React.Fragment key={crumb.id}>
            {"/ "}
            <span
              onClick={() => handleBreadcrumbClick(crumb.id, crumb)}
              className={
                crumb.id === currentWorkflowId
                  ? "current-breadcrumb"
                  : "not-current-breadcrumb"
              }
            >
              {crumb.name}
            </span>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default Breadcrumbs;
