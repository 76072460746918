import React, { createContext, useContext, useState, useEffect } from "react";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userIsQurrent, setUserIsQurrent] = useState(() => {
    const storedValue = sessionStorage.getItem("userIsQurrent");
    return storedValue === "true";
  });

  useEffect(() => {
    const handleStorageChange = () => {
      const storedValue = sessionStorage.getItem("userIsQurrent");
      setUserIsQurrent(storedValue === "true");
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    console.log("UserProvider: userIsQurrent", userIsQurrent);
  }, [userIsQurrent]);

  return (
    <UserContext.Provider value={{ userIsQurrent, setUserIsQurrent }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
