import React from "react";
import { useNavigate } from "react-router-dom";
import whiteLogo from "./assets/white_qurrent_logo.png";
import "./App.css";

function Header() {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Google Sign-Out
    if (window.google && window.google.accounts && window.google.accounts.id) {
      window.google.accounts.id.disableAutoSelect();
      window.google.accounts.id.revoke(
        localStorage.getItem("user_email"),
        () => {
          console.log("Google consent revoked");
        }
      );
    }

    // Clear session storage
    sessionStorage.removeItem("user"); // Change to sessionStorage
    sessionStorage.removeItem("session"); // Change to sessionStorage
    sessionStorage.removeItem("bearerToken"); // Change to sessionStorage
    sessionStorage.removeItem("user_email"); // Change to sessionStorage

    // Redirect to login page
    navigate("/login");

    // Reload the page to reset Google's auth state
    window.location.reload();
  };

  return (
    <header className="App-header">
      <div className="App-header-left">
        <img src={whiteLogo} className="App-logo" alt="logo" />
      </div>
      <div className="App-header-right">
        <div className="App-account">
          <span className="App-username">
            {localStorage.getItem("user_name") || "Username"}{" "}
            {/* Use user's name */}
          </span>
          <div className="App-dropdown">
            <button className="App-dropdown-button">▼</button>
            <div className="App-dropdown-content">
              <button onClick={handleLogout} className="logout-button">
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
