import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import whiteLogo from "./assets/white_qurrent_logo.png";
import superdupontImage from "./assets/superdupont.png";
import "./App.css";
import axios from "axios";
import { useSuperdupont } from "./contexts/SuperdupontContext.js";
import { useObservableView } from "./contexts/ObservableViewContext.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoon,
  faSignOutAlt,
  faChevronDown,
  faBug,
} from "@fortawesome/free-solid-svg-icons";
import config from "./config.js";

function Header({ userIsQurrent }) {
  const { logout, user, getAccessTokenSilently } = useAuth0();
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );

  const { isSuperdupont, toggleSuperdupont } = useSuperdupont(false);
  const { isObservableViewActive, toggleObservableView } = useObservableView();
  const [isSuperdupontActive, setIsSuperdupontActive] = useState(
    JSON.parse(sessionStorage.getItem("superdupontMode")) || false
  );
  const apiUrl = config.apiBaseUrl;

  useEffect(() => {
    console.log("Header: userIsQurrent", userIsQurrent);
    if (!userIsQurrent && isObservableViewActive) {
      toggleObservableView();
    }
  }, [userIsQurrent, isObservableViewActive, toggleObservableView]);

  useEffect(() => {
    document.body.classList.toggle("dark-mode", darkMode);
    document.documentElement.classList.toggle("dark-mode", darkMode);
    document.body.classList.toggle("light-mode", !darkMode);
    document.documentElement.classList.toggle("light-mode", !darkMode);
  }, [darkMode]);

  const endSession = async () => {
    const sessionId = sessionStorage.getItem("session_id");
    console.log("sessionId", sessionId);
    sessionStorage.clear();
    if (sessionId) {
      try {
        const token = await getAccessTokenSilently();
        const response = await axios.post(
          `${apiUrl}/sessions/${sessionId}/end`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
          }
        );
        console.log("Session ended:", response.data);
        sessionStorage.removeItem("session_id");
        sessionStorage.clear();
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.warn("Session not found:", sessionId);
        } else {
          console.error("Error ending session:", error);
        }
      }
    }
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      endSession();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleLogout = async () => {
    await endSession();
    const auth0Domain = config.auth0Domain;
    logout({
      returnTo: window.location.origin,
      federated: true,
      end_session_endpoint: `https://${auth0Domain}/v2/logout?federated`,
    });
  };

  const toggleDarkMode = () => {
    const isDarkMode = !darkMode;
    setDarkMode(isDarkMode);
    localStorage.setItem("theme", isDarkMode ? "dark" : "light");
    console.log("SESSION ID HERE: ", sessionStorage.getItem("session_id"));
  };

  const handleSuperdupontToggle = () => {
    if (userIsQurrent) {
      toggleSuperdupont();
      setIsSuperdupontActive((prevState) => {
        const newState = !prevState;
        sessionStorage.setItem("superdupontMode", JSON.stringify(newState));
        if (!newState) {
          toggleObservableView();
        }
        return newState;
      });
    }
  };

  return (
    <header className="App-header">
      <div className="App-header-left">
        <img src={whiteLogo} className="App-logo" alt="logo" />
      </div>
      <div className="App-header-right">
        <div className="App-account">
          <div className="App-dropdown">
            <div className="App-username-container">
              <span className="App-username">
                {isSuperdupontActive && isObservableViewActive && (
                  <FontAwesomeIcon icon={faBug} className="debug-icon-header" />
                )}
                {isSuperdupontActive && (
                  <img
                    src={superdupontImage}
                    className="agent-icon-header"
                    alt="superdupont"
                  />
                )}
                {user ? user.name || user.email : "Username"}{" "}
              </span>
              <button className="App-dropdown-button">
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="small-chevron"
                />
              </button>
            </div>
            <div className="App-dropdown-content">
              {userIsQurrent && (
                <>
                  <button
                    onClick={handleSuperdupontToggle}
                    className={`superdupont-toggle-button ${
                      isSuperdupontActive ? "active" : ""
                    }`}
                  >
                    <div className="superdupont-icon">
                      <img
                        src={superdupontImage}
                        className="shrinked-image"
                        alt="superdupont"
                      />
                    </div>
                    Toggle Superdupont
                  </button>
                  {isSuperdupontActive && (
                    <button
                      onClick={toggleObservableView}
                      className={`observable-view-toggle-button ${
                        isObservableViewActive ? "active" : ""
                      }`}
                    >
                      <div className="observable-view-icon">
                        <FontAwesomeIcon icon={faBug} />
                      </div>
                      Toggle debug mode
                    </button>
                  )}
                </>
              )}
              <button
                onClick={toggleDarkMode}
                className={`dark-mode-button ${darkMode ? "active" : ""}`}
              >
                <div className="dark-mode-icon">
                  <FontAwesomeIcon icon={faMoon} />
                </div>
                {darkMode ? "Toggle Light Mode" : "Toggle Dark Mode"}
              </button>
              <button onClick={handleLogout} className={`logout-button`}>
                <div className="logout-icon">
                  <FontAwesomeIcon icon={faSignOutAlt} />
                </div>
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
