import React, { createContext, useState, useCallback } from "react";
import { getConfig } from "../config.js";

export const EnvironmentContext = createContext();

export const EnvironmentProvider = ({ children }) => {
  const [env, setEnv] = useState("dev");

  const switchEnvironment = useCallback(
    (newEnv) => {
      if (newEnv === undefined) {
        newEnv = env === "prod" ? "dev" : "prod";
      }
      // Update the API route
      // if (newEnv === "dev") {
      //   console.log("Switching to dev");
      //   // config.apiBaseUrl = "https://dev.api.qurrent.ai"; // TODO: change this back to "https://dev.api.qurrent.ai"
      //   config.apiBaseUrl = "http://localhost:8000";
      //   // config.auth0Domain = "dev.auth.qurrent.ai";
      // } else {
      //   console.log("Switching to prod");
      //   // config.apiBaseUrl = "https://api.qurrent.ai"; // TODO: change this back to "https://api.qurrent.ai"
      //   config.apiBaseUrl = "http://localhost:8001";
      //   // config.auth0Domain = "auth.qurrent.ai";
      // }

      console.log(`Switching to ${newEnv}`);
      const newConfig = getConfig(newEnv);

      // Update the environment
      setEnv(newEnv);

      // Log the new configuration
      console.log("New config.apiOrigin", newConfig.apiOrigin);
      console.log("New auth0Domain", newConfig.domain);
    },
    [env]
  );

  return (
    <EnvironmentContext.Provider value={{ env, switchEnvironment }}>
      {children}
    </EnvironmentContext.Provider>
  );
};
