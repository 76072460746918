import React from "react";
import "../App.css"; // Ensure this path is correct
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleDot } from "@fortawesome/free-solid-svg-icons";

const OutputPopup = ({ outputs, onClose }) => {
  // Function to convert plain text URLs into clickable links

  const renderOutput = (output) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return output.split(urlRegex).map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      }
      // Split on literal \n and join with line breaks
      return part.split("\n").map((line, i) => (
        <React.Fragment key={i}>
          {line}
          {i < part.split("\n").length - 1 && <br />}
        </React.Fragment>
      ));
    });
  };

  return (
    <div className="output-popup-overlay" onClick={onClose}>
      <div
        className="output-popup-content"
        onClick={(e) => e.stopPropagation()}
        style={{ display: "flex", overflow: "hidden" }}
      >
        {outputs.length > 1 && (
          <div
            className="output-navigation"
            style={{ display: "flex", alignItems: "center" }}
          >
            <ul>
              {outputs.map((_, index) => (
                <li key={index}>
                  <button
                    onClick={() => {
                      document
                        .getElementById(`output-${index}`)
                        .scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                    }}
                  >
                    <div>
                      <FontAwesomeIcon icon={faCircleDot} />
                    </div>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
        <div
          className="output-content"
          style={outputs.length === 1 ? { paddingLeft: "20px" } : {}}
        >
          <div className="output-popup-header">
            <div className="output-popup-title">Output</div>
            <button className="output-close-button" onClick={onClose}>
              ×
            </button>
          </div>
          <hr className="divider-popup" />
          <ul>
            {outputs.map((output, index) => (
              <React.Fragment key={index}>
                <li id={`output-${index}`} style={{ marginBottom: "12px" }}>
                  {renderOutput(output)}
                </li>
                {index < outputs.length - 1 && <hr className="divider-popup" />}
              </React.Fragment>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OutputPopup;
