import React, { useState, useRef, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "react-calendar/dist/Calendar.css";
import "./App.css";
import Header from "./Header.js";
import { useAuth0 } from "@auth0/auth0-react";
import ReactFlow, { Controls, Background, useReactFlow } from "reactflow";
import "reactflow/dist/style.css";
import dagre from "dagre";
import ActionNodePopup from "./workflow_components/ActionNodePopup.js";
import LLMCallNodePopup from "./workflow_components/LLMCallNodePopup.js";
import ObservableNodePopup from "./workflow_components/ObservableNodePopup.js";
import AgentNodePopup from "./workflow_components/AgentNodePopup.js";
import OutputPopup from "./workflow_components/OutputPopup.js";
import ErrorPopup from "./workflow_components/ErrorPopup.js";
import LeftDrawer from "./workflow_components/LeftDrawer.js";
import WorkflowTable from "./workflow_components/WorkflowTable.js";
import Breadcrumbs from "./workflow_components/Breadcrumbs.js";
import EmptyDrawerMessagePopup from "./workflow_components/EmptyDrawerMessagePopup.js";
import { useObservableView } from "./contexts/ObservableViewContext.js";

import {
  formatDate,
  formatNodeName,
  formatId,
  formatContent,
  getDomainFromEmail,
} from "./utils/formatUtils.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRobot,
  faPenToSquare,
  faGear,
  faComments,
  faAnglesLeft,
  faAnglesRight,
  faAnglesDown,
  faAnglesUp,
} from "@fortawesome/free-solid-svg-icons";
import { useBreadcrumbs } from "./contexts/BreadcrumbsContext.js";
import { getSortIcon, getStatusIcon } from "./utils/formatUtils.js";
import {
  getLayoutedElements,
  combineRuns,
  toggleNodeVisibility,
} from "./utils/workflowUtils.js";
import { useApiUtils } from "./utils/apiUtils.js";
function WorkflowDetails({ userIsQurrent }) {
  const {
    fetchFilteredInstances,
    fetchChildWorkflows,
    fetchWorkflowStructure,
    fetchLatestWorkflowInstance,
    fetchLlmCallSummary,
    fetchObservableSummary,
  } = useApiUtils();

  const { setCenter } = useReactFlow();

  const [workflowDescription, setWorkflowDescription] = useState("");
  const [showCustomCalendar, setShowCustomCalendar] = useState(false);
  const [sortColumn, setSortColumn] = useState("startTime");
  const [sortDirection, setSortDirection] = useState("desc");
  const [runs, setRuns] = useState([]);
  const [activeFilters, setActiveFilters] = useState({
    status: null,
    run_start: null,
    run_end: null,
  });
  const [showStartCalendar, setShowStartCalendar] = useState(false);
  const [showEndCalendar, setShowEndCalendar] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [workflowStructure, setWorkflowStructure] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [hasMore, setHasMore] = useState(true);

  const [error, setError] = useState(null);
  const [showError, setShowError] = useState(false);

  const popupRef = useRef(null);

  const [selectedRunId, setSelectedRunId] = useState(null);
  const [showEmptyDrawerPopup, setShowEmptyDrawerPopup] = useState(false);

  const { user, getAccessTokenSilently } = useAuth0();

  const [openStatusDropdown, setOpenStatusDropdown] = useState(false);
  const [openStartDropdown, setOpenStartDropdown] = useState(false);
  const [openEndDropdown, setOpenEndDropdown] = useState(false);

  const [runStartFilter, setRunStartFilter] = useState([null, null]);
  const [runEndFilter, setRunEndFilter] = useState([null, null]);
  const [runStartDisplay, setRunStartDisplay] = useState(null);
  const [runEndDisplay, setRunEndDisplay] = useState(null);
  const startCalendarRef = useRef(null);
  const endCalendarRef = useRef(null);
  const startDropdownRef = useRef(null);
  const endDropdownRef = useRef(null);
  const statusDropdownRef = useRef(null);
  const [customDateType, setCustomDateType] = useState(null);
  const [copied, setCopied] = useState(false);
  const [total, setTotal] = useState(0); // Add total to the state
  const [spinningColumn, setSpinningColumn] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const [hiddenNodes, setHiddenNodes] = useState(new Set());
  const [hiddenEdges, setHiddenEdges] = useState(new Set());

  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState([]);
  const [loadingStructure, setLoadingStructure] = useState(false);

  const [expandedRunIds, setExpandedRunIds] = useState(new Set());
  const { breadcrumbs, setBreadcrumbs } = useBreadcrumbs();
  const [observableDetails, setObservableDetails] = useState([]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLeftDrawerOpen, setIsLeftDrawerOpen] = useState(false);
  const [highlightedNodeId, setHighlightedNodeId] = useState(null);
  const drawerRef = useRef(null);
  const [hiddenChildrenNodeIds, setHiddenChildrenNodeIds] = useState(new Set());
  const [isFlowReady, setIsFlowReady] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(0);
  const observableListRef = useRef([]);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [childWorkflows, setChildWorkflows] = useState({});
  const [currentWorkflowId, setCurrentWorkflowId] = useState(null);
  const [currentWorkflowName, setCurrentWorkflowName] = useState(null);
  const { workflowId, workflowInstanceId } = useParams();
  useState(workflowInstanceId);
  const nodeWidth = 150;
  const nodeHeight = 50;
  const originalWarn = console.warn;
  const [llmSummary, setLlmSummary] = useState("");
  const [observableSummary, setObservableSummary] = useState("");
  const [visibleNodes, setVisibleNodes] = useState([]);
  const [visibleEdges, setVisibleEdges] = useState([]);
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const abortControllerRef = useRef(null);
  const { isObservableViewActive } = useObservableView(); // Use the context
  const [workflowCreatedAt, setWorkflowCreatedAt] = useState(null);
  const [lastClickedNodeIndex, setLastClickedNodeIndex] = useState(null);
  const combinedRuns = combineRuns(runs);
  const selectedRunData = combinedRuns.find((run) => run.id === selectedRunId);
  const selectedRunOutput = selectedRunData ? selectedRunData.output : [];

  const [initialHideDone, setInitialHideDone] = useState(false); // New state to track initial hiding
  const [workflowIsClicked, setWorkflowIsClicked] = useState(false);
  const [isRunClick, setIsRunClick] = useState(false);

  console.warn = (message, ...args) => {
    if (
      typeof message === "string" &&
      message.includes("[React Flow]: Node type")
    ) {
      return;
    }
    originalWarn(message, ...args);
  };

  async function initializeData(
    thisWorkflowId,
    thisWorkflowInstanceId,
    thisWorkflowName
  ) {
    setIsOverlayVisible(true);
    setSortColumn(sortColumn);
    setSortDirection(sortDirection);
    setCurrentPage(1);
    setRuns([]);

    setCurrentWorkflowName(thisWorkflowName);
    setCurrentWorkflowId(thisWorkflowId);

    const token = await getAccessTokenSilently();
    const domain = getDomainFromEmail(user.email);

    try {
      const allData = await fetchFilteredInstances(
        thisWorkflowId ? thisWorkflowId : currentWorkflowId,
        workflowInstanceId ? workflowInstanceId : thisWorkflowInstanceId,
        1,
        activeFilters,
        sortColumn,
        sortDirection,
        token,
        domain
      );

      setTotal(allData.response.data.total_count || 0);
      setHasMore(allData.processedInstances.length === 10);
      setTotalPages(Math.ceil(allData.response.data.total_count / 10));
      setRuns(allData.processedInstances);

      setIsDrawerOpen(true);
      setIsLeftDrawerOpen(true);

      const idsList = allData.processedInstances.map((instance) => instance.id);

      if (allData.response.data.customer_id) {
        const thisCustomerId = allData.response.data.customer_id;
        const token = await getAccessTokenSilently();
        try {
          const newChildWorkflows = await fetchChildWorkflows(
            idsList,
            thisCustomerId,
            token
          );
          setChildWorkflows(newChildWorkflows);
        } catch (error) {
          console.error("Error fetching child workflows:", error);
        }

        if (allData.processedInstances) {
          thisWorkflowName = allData.response.data.workflow_name;
          thisWorkflowId = allData.response.data.workflow_id;
          const description = allData.response.data.workflow_description
            ? allData.response.data.workflow_description
            : "No description available";
          setWorkflowDescription(description);
          setCurrentWorkflowName(thisWorkflowName);
          setCurrentWorkflowId(thisWorkflowId);
          setWorkflowCreatedAt(
            formatDate(allData.response.data.workflow_created_at)
          );
          if (thisWorkflowId && thisWorkflowName) {
            updateBreadcrumbs(thisWorkflowId, thisWorkflowName);
          }
          const firstRunId = allData.processedInstances[0].id;
          setSelectedRunId(firstRunId);
          await processWorkflowStructure(thisWorkflowId, firstRunId);
        } else {
          console.log("Customer ID is not set.");
        }
      }
    } catch (error) {
      console.error("Error fetching filtered instances:", error);
    } finally {
      setIsOverlayVisible(false);
    }
  }

  async function processWorkflowStructure(workflowId, runId) {
    const token = await getAccessTokenSilently();
    const domain = getDomainFromEmail(user.email);
    try {
      const structureData = await fetchWorkflowStructure(
        workflowId,
        runId,
        token,
        domain
      );
      if (structureData) {
        setWorkflowStructure(structureData);
        const observableDetails = structureData.filter(
          (item) => item.type === "observable"
        );

        setObservableDetails(observableDetails);

        setHighlightedNodeId(structureData[0].id);
      } else {
        console.warn("Workflow structure is null or undefined.");
      }
    } catch (error) {
      console.error("Error fetching workflow structure:", error);
    }
  }

  useEffect(() => {
    if (!workflowStructure && !error) {
      const timer = setTimeout(() => {
        setShowError(true);
      }, 30000);

      return () => clearTimeout(timer);
    }
  }, [workflowStructure, error]);

  const handleDropdownClick = (nodeId) => {
    console.log("node id: ", nodeId);
    setHiddenChildrenNodeIds((prevIds) => {
      const newIds = new Set(prevIds);
      if (newIds.has(nodeId)) {
        newIds.delete(nodeId);
      } else {
        newIds.add(nodeId);
      }
      return newIds;
    });
  };

  useEffect(() => {
    if (!workflowStructure) {
      console.log("No workflow structure found, EXITING EARLY");
      return; // Exit early if there's no workflow structure
    }

    const nodes = [];
    var edges = [];
    const positionMap = {};
    // Process workflow structure
    workflowStructure.forEach((item) => {
      const type = item.type || "default";
      let icon;

      // Icon mapping
      switch (type) {
        case "observable":
          icon = <FontAwesomeIcon className="node-icon" icon={faPenToSquare} />;
          break;
        case "llm_call":
          icon = <FontAwesomeIcon className="node-icon" icon={faComments} />;
          break;
        case "action":
          icon = <FontAwesomeIcon className="node-icon" icon={faGear} />;
          break;
        default:
          icon = null;
      }

      if (
        isObservableViewActive &&
        userIsQurrent &&
        (item.type === "action" || item.type === "llm_call") &&
        !item.trigger_action_id &&
        !item.llm_call_id &&
        !item.trigger_observable_id &&
        item.trigger_workflow_instance_id
      ) {
        // Add a placeholder observable node
        const placeholderObservableId = `placeholder-observable-${item.id}`;
        nodes.push({
          id: placeholderObservableId,
          data: {
            label: "Placeholder Task",
          },
          type: "observable",
          position: { x: 250, y: 50 }, // Adjust position as needed
          style: {
            backgroundColor: "var(--background-color-node)",
            borderColor: "var(--border-color)",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "5px",
          },
        });

        // Add a placeholder agent node
        const placeholderAgentId = `placeholder-agent-${item.id}`;
        nodes.push({
          id: placeholderAgentId,
          data: {
            label: "Placeholder Agent",
          },
          type: "agent",
          position: { x: 250, y: 50 }, // Adjust position as needed
          style: {
            backgroundColor: "var(--background-color-node)",
            borderColor: "var(--border-color)",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "5px",
          },
        });

        // Connect the placeholder agent to the placeholder observable
        edges.push({
          id: `e${placeholderAgentId}-${placeholderObservableId}`,
          source: placeholderAgentId,
          target: placeholderObservableId,
        });

        // Connect the placeholder observable to the current item
        edges.push({
          id: `e${placeholderObservableId}-${item.id}`,
          source: placeholderObservableId,
          target: item.id,
        });
      }

      // Placeholder agent node
      if (
        isObservableViewActive &&
        userIsQurrent &&
        item.type === "observable" &&
        !item.trigger_console_agent_id &&
        !item.trigger_observable_id
      ) {
        const placeholderAgentId = `placeholder-observable-${item.id}`;
        nodes.push({
          id: placeholderAgentId,
          data: {
            label: "Placeholder Agent",
          },
          type: "agent",
          position: { x: 250, y: 50 }, // Adjust position as needed
          style: {
            backgroundColor: "var(--background-color-node)",
            borderColor: "var(--border-color)",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "5px",
          },
        });

        // Connect the placeholder agent to the observable
        edges.push({
          id: `e${placeholderAgentId}-${item.id}`,
          source: placeholderAgentId,
          target: item.id,
        });
      }

      // Placeholder observable node
      if (
        isObservableViewActive &&
        userIsQurrent &&
        item.type === "observable" &&
        !item.trigger_console_agent_id &&
        !item.trigger_observable_id
      ) {
        const placeholderAgentId = `placeholder-agent-${item.id}`;
        nodes.push({
          id: placeholderAgentId,
          data: {
            label: "Placeholder Agent",
          },
          type: "agent",
          position: { x: 250, y: 50 }, // Adjust position as needed
          style: {
            backgroundColor: "var(--background-color-node)",
            borderColor: "var(--border-color)",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "5px",
          },
        });

        // Connect the placeholder agent to the observable
        edges.push({
          id: `e${placeholderAgentId}-${item.id}`,
          source: placeholderAgentId,
          target: item.id,
        });
      }

      const position = item.position || { x: 250, y: 0 };
      const timestamp = new Date(item.created_at).getTime();
      const baseY = positionMap[timestamp] || 0;

      const hasChildren = workflowStructure.some(
        (nextItem) =>
          nextItem.trigger_action_id === item.id ||
          nextItem.llm_call_id === item.id ||
          nextItem.trigger_observable_id === item.id
      );

      // Node creation
      nodes.push({
        id: item.id,
        data: {
          item,
          label: (
            <div
              id={item.id}
              className="node-label"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexGrow: 1,
                  overflow: "hidden",
                }}
              >
                {icon}
                <span
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  onClick={() => {
                    setSelectedNode(item);
                  }}
                >
                  {type === "llm_call"
                    ? `LLM Call ...${item.id.slice(-5)}`
                    : type === "observable" || type === "action"
                    ? item.function_name
                    : "Node"}
                </span>
              </div>
              {type === "observable" && hasChildren && (
                <div style={{ marginLeft: "4px" }}>
                  <FontAwesomeIcon
                    icon={
                      hiddenChildrenNodeIds.has(item.id)
                        ? faAnglesDown
                        : faAnglesUp
                    }
                    className="node-dropdown-icon"
                    style={{ fontSize: "0.8em" }}
                    onClick={(event) => {
                      event.stopPropagation();
                      handleDropdownClick(item.id);
                      toggleNodeVisibility(
                        item.id,
                        edges,
                        nodes,
                        hiddenNodes,
                        setHiddenNodes
                      );
                    }}
                  />
                </div>
              )}
            </div>
          ),
        },
        type: type,
        position: item.position,
        style: {
          backgroundColor: "var(--background-color-node)",
          borderColor: "var(--border-color)",
          borderWidth: item.id === highlightedNodeId ? "2px" : "1px",
          borderStyle: "solid",
          borderRadius: "5px",
        },
      });

      positionMap[timestamp] = baseY + nodeHeight + 10; // Add some spacing between stacked nodes

      // Agent node
      if (item.trigger_console_agent_id && !item.trigger_observable_id) {
        const uniqueAgentId = `${item.trigger_console_agent_id}-${timestamp}`; // Create a unique id for each agent
        const agentNode = {
          id: uniqueAgentId,
          type: "agent",
          data: {
            item: {
              ...item,
              console_agent: item.console_agent,
            },
            label: (
              <div
                className="node-label"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexGrow: 1,
                    overflow: "hidden",
                  }}
                >
                  <FontAwesomeIcon className="node-icon" icon={faRobot} />
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    onClick={() => {
                      setSelectedNode(item);
                    }}
                  >
                    {formatNodeName(item.console_agent.name)}
                  </span>
                </div>
                <div style={{ marginLeft: "4px" }}></div>
              </div>
            ),
          },
          position: { x: position.x - 100, y: position.y },
          style: {
            backgroundColor: "var(--background-color-node)",
            borderColor: "var(--border-color)",
            borderWidth: item.id === highlightedNodeId ? "2px" : "1px",
            borderStyle: "solid",
            borderRadius: "5px",
          },
        };

        nodes.push(agentNode);

        edges.push({
          id: `e${uniqueAgentId}-${item.id}`,
          source: uniqueAgentId,
          target: item.id,
        });
      }

      // Agent node FROM ANOTHER WORKFLOW
      if (
        item.trigger_console_agent_id &&
        item.trigger_observable_id &&
        item.parent_workflow_instance_id
      ) {
        const uniqueAgentId = `${item.trigger_console_agent_id}-${timestamp}`; // Create a unique id for each agent
        const agentNode = {
          id: uniqueAgentId,
          type: "agent",
          data: {
            item: {
              ...item,
              console_agent: item.console_agent,
            },
            label: (
              <div
                className="node-label"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexGrow: 1,
                    overflow: "hidden",
                  }}
                >
                  <FontAwesomeIcon className="node-icon" icon={faRobot} />
                  <span
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    onClick={() => {
                      setSelectedNode(item);
                    }}
                  >
                    {formatNodeName(item.console_agent.name)}
                  </span>
                </div>
                <div style={{ marginLeft: "4px" }}></div>
              </div>
            ),
          },
          position: { x: 250, y: 50 },
          style: {
            backgroundColor: "var(--background-color-node)",
            borderColor: "var(--border-color)",
            borderWidth: item.id === highlightedNodeId ? "2px" : "1px",
            borderStyle: "solid",
            borderRadius: "5px",
          },
        };

        nodes.push(agentNode);

        edges.push({
          id: `e${uniqueAgentId}-${item.id}`,
          source: uniqueAgentId,
          target: item.id,
        });
      }

      // Action edge
      if (item.trigger_action_id) {
        edges.push({
          id: `e${item.trigger_action_id}-${item.id}`,
          source: item.trigger_action_id,
          target: item.id,
        });
      }

      // LLM call edge
      if (item.llm_call_id) {
        edges.push({
          id: `e${item.llm_call_id}-${item.id}`,
          source: item.llm_call_id,
          target: item.id,
        });
      }

      // Observable edge
      if (item.trigger_observable_id) {
        edges.push({
          id: `e${item.trigger_observable_id}-${item.id}`,
          source: item.trigger_observable_id,
          target: item.id,
        });
      }
    });

    // Initial hiding of child nodes for observables
    if (!initialHideDone) {
      workflowStructure.forEach((item) => {
        if (item.type === "observable") {
          toggleNodeVisibility(
            item.id,
            edges,
            nodes,
            hiddenNodes,
            setHiddenNodes
          );
        }
      });

      setInitialHideDone(true); // Mark initial hiding as done
    }

    console.log("NODES: ", nodes);
    console.log("EDGES: ", edges);

    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      nodes,
      edges,
      nodeWidth,
      nodeHeight,
      hiddenNodes,
      hiddenEdges
    );

    const updatedVisibleNodes = layoutedNodes.map((node) => ({
      ...node,
      hidden: hiddenNodes.has(node.id),
    }));

    const updatedVisibleEdges = layoutedEdges.map((edge) => ({
      ...edge,
      hidden: hiddenEdges.has(edge.id),
    }));

    setVisibleNodes(updatedVisibleNodes);
    setVisibleEdges(updatedVisibleEdges);

    console.log("updatedVisibleNodes: ", updatedVisibleNodes);
    if (updatedVisibleNodes.length > 0 && !initialHideDone) {
      const firstNode = updatedVisibleNodes[0];
      setTimeout(() => {
        setHighlightedNodeId(firstNode.id);
        setShowEmptyDrawerPopup(true);

        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        console.log(
          "Setting center to: ",
          firstNode.position.x + viewportWidth / 5,
          firstNode.position.y + viewportHeight / 8
        );

        setCenter(
          firstNode.position.x + viewportWidth / 5,
          firstNode.position.y + viewportHeight / 8,
          {
            zoom: 1.3,
            duration: 800,
          }
        );
        setInitialHideDone(true);
      }, 100);
    }

    const updatedObservableDetails = observableDetails.map((item) => {
      const visibleNode = updatedVisibleNodes.find(
        (node) => node.id === item.id
      );
      if (visibleNode) {
        const { data, ...restVisibleNode } = visibleNode;
        return { ...item, ...restVisibleNode };
      }
      return item;
    });

    setObservableDetails(updatedObservableDetails);

    // Cleanup function (if needed)
    return () => {
      // Perform any necessary cleanup here
    };
  }, [workflowStructure, hiddenNodes, hiddenEdges, isObservableViewActive]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setHighlightedNodeId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const renderWorkflowStructure = () => {
    return (
      <div
        className={`workflow-container ${
          isOverlayVisible ? "loading-overlay" : ""
        }`}
        ref={containerRef}
      >
        <ReactFlow
          nodes={visibleNodes.map((node) => ({
            ...node,
            className: node.id === selectedNode?.id ? "selected-node" : "",
          }))}
          edges={visibleEdges}
          onInit={handleFlowLoad}
          onNodeClick={handleNodeClick}
          onPaneClick={handlePaneClick}
          style={{ width: "100%", height: "100%" }}
          fitView
          className="reactflow-container"
          ref={containerRef}
          nodesDraggable={true}
          nodesConnectable={false}
          snapToGrid={true}
          snapGrid={[15, 15]}
          proOptions={{ hideAttribution: true }}
        >
          {loadingStructure && (
            <div className="loading-spinner-corner-workflow">
              <div className="loading-spinner"></div>
            </div>
          )}
          <Controls className="controls-style" showInteractive={false} />
          <Background />
        </ReactFlow>
      </div>
    );
  };

  const handlePaneClick = () => {
    setSelectedNode(null);
    setShowEmptyDrawerPopup(true); // Show the empty drawer popup
  };

  const handleFlowLoad = () => {
    setIsFlowReady(true);
  };

  const handleFilterToggle = (filterType, option = null) => {
    if (option) {
      let filterValue, displayValue;
      if (option === "Last 7 days" || option === "Last 30 days") {
        const endDate = new Date();
        const startDate = new Date();
        startDate.setDate(
          endDate.getDate() - (option === "Last 7 days" ? 7 : 30)
        );
        filterValue = JSON.stringify([
          startDate.toISOString(),
          endDate.toISOString(),
        ]);
        displayValue = option;
      } else if (option === "Custom") {
        console.log("Custom date selected");
        setCustomDateType(filterType);
        setShowCustomCalendar(true);
      }

      let newFilters = { ...activeFilters };
      if (filterType === "runStart") {
        setRunStartFilter(filterValue);
        setRunStartDisplay(displayValue);
        newFilters.run_start = filterValue;
      } else if (filterType === "runEnd") {
        setRunEndFilter(filterValue);
        setRunEndDisplay(displayValue);
        newFilters.run_end = filterValue;
      } else if (filterType === "status") {
        newFilters.status = option;
      }
      setActiveFilters(newFilters);
      handleFilterChange(newFilters);
    } else {
      const newFilters = { ...activeFilters };
      setActiveFilters(newFilters);
      handleFilterChange(newFilters);
    }
    toggleDropdown(filterType);
  };

  const handleCustomDateSelection = (dates) => {
    const [start, end] = dates;
    if (start && end) {
      const formattedRange = JSON.stringify([
        start.toISOString(),
        end.toISOString(),
      ]);
      console.log("formattedRange", formattedRange);
      if (customDateType === "runStart") {
        console.log("runStart", formattedRange);
        setRunStartFilter(formattedRange);
        setRunStartDisplay(
          `${start.toLocaleDateString()} - ${end.toLocaleDateString()}`
        );
        const newFilters = { ...activeFilters, run_start: formattedRange };
        setActiveFilters(newFilters);
        handleFilterChange(newFilters);
      } else if (customDateType === "runEnd") {
        console.log("runEnd", formattedRange);
        setRunEndFilter(formattedRange);
        setRunEndDisplay(
          `${start.toLocaleDateString()} - ${end.toLocaleDateString()}`
        );
        const newFilters = { ...activeFilters, run_end: formattedRange };
        setActiveFilters(newFilters);
        handleFilterChange(newFilters);
      }
      setShowCustomCalendar(false); // Close the calendar after selection
    }
  };

  const toggleDropdown = (filterType) => {
    if (filterType === "status") {
      setOpenStatusDropdown((prev) => !prev);
      setOpenEndDropdown(false);
      setOpenStartDropdown(false);
    } else if (filterType === "runStart") {
      setOpenStartDropdown((prev) => !prev);
      setOpenEndDropdown(false);
      setOpenStatusDropdown(false);
    } else if (filterType === "runEnd") {
      setOpenEndDropdown((prev) => !prev);
      setOpenStartDropdown(false);
      setOpenStatusDropdown(false);
    }
    setShowStartCalendar(false);
    setShowEndCalendar(false);
  };

  const removeFilter = (index) => {
    setActiveFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      // Determine which filter to remove based on index
      if (index === 0) {
        newFilters.status = null;
      } else if (index === 1) {
        newFilters.run_start = null;
      } else if (index === 2) {
        newFilters.run_end = null;
      }
      handleFilterChange(newFilters);
      return newFilters;
    });
  };

  const toggleFilter = (status, runStart, runEnd) => {
    setActiveFilters((prev) => ({
      ...prev,
      status: status,
      run_start: runStart,
      run_end: runEnd,
    }));
  };

  const handleIdClick = (value) => {
    navigator.clipboard.writeText(value);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  const handleClosePopup = () => {
    setSelectedNode(null);
    setIsDrawerOpen(false);
    setShowEmptyDrawerPopup(false); // Close the popup
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setSelectedNode(null);
    }

    if (
      statusDropdownRef.current &&
      !statusDropdownRef.current.contains(event.target) &&
      startDropdownRef.current &&
      !startDropdownRef.current.contains(event.target) &&
      endDropdownRef.current &&
      !endDropdownRef.current.contains(event.target) &&
      startCalendarRef.current &&
      !startCalendarRef.current.contains(event.target) &&
      endCalendarRef.current &&
      !endCalendarRef.current.contains(event.target)
    ) {
      setOpenStatusDropdown(null);
      setOpenStartDropdown(null);
      setOpenEndDropdown(null);
      setShowCustomCalendar(null);
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSortClick = (column) => {
    if (spinningColumn !== column) {
      setSpinningColumn(column);

      const newDirection =
        sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
      handleSortChange(column, newDirection);

      setTimeout(() => {
        setSpinningColumn(null);
      }, 200);
    }
  };

  const handleSortChange = async (newSortColumn, newSortDirection) => {
    const token = await getAccessTokenSilently();
    const domain = getDomainFromEmail(user.email);
    setSortColumn(newSortColumn);
    setSortDirection(newSortDirection);
    setCurrentPage(1);
    setIsLoading(true);
    try {
      const allData = await fetchFilteredInstances(
        currentWorkflowId,
        null,
        1,
        activeFilters,
        newSortColumn,
        newSortDirection,
        token,
        domain
      );

      console.log("all data handleSortChange: ", allData);
      setTotal(allData.response.data.total_count || 0);
      setRuns(allData.processedInstances);
      setHasMore(allData.processedInstances.length === 10);
      setTotalPages(Math.ceil(allData.response.data.total_count / 10));
    } catch (error) {
      console.error("Error fetching filtered instances:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = async (newPage) => {
    const token = await getAccessTokenSilently();
    const domain = getDomainFromEmail(user.email);
    setIsLoading(true);

    try {
      const { response, processedInstances } = await fetchFilteredInstances(
        currentWorkflowId,
        null,
        newPage,
        activeFilters,
        sortColumn,
        sortDirection,
        token,
        domain
      );

      setTotal(response.data.total_count || 0);
      setRuns((prevRuns) => {
        const existingIds = new Set(prevRuns.map((run) => run.id));
        const newRuns = processedInstances.filter(
          (run) => !existingIds.has(run.id)
        );
        return [...prevRuns, ...newRuns];
      });
      setHasMore(processedInstances.length === 10);
      setTotalPages(Math.ceil(response.data.total_count / 10));
      setCurrentPage(newPage);
    } catch (error) {
      console.error("Error fetching filtered instances:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilterChange = async (newFilters) => {
    const token = await getAccessTokenSilently();
    const domain = getDomainFromEmail(user.email);
    setIsLoading(true);
    setSortColumn(sortColumn);
    setSortDirection(sortDirection);
    setCurrentPage(1);
    setActiveFilters(newFilters);
    try {
      const allData = await fetchFilteredInstances(
        currentWorkflowId,
        null,
        1,
        newFilters,
        sortColumn,
        sortDirection,
        token,
        domain
      );

      setTotal(allData.response.data.total_count || 0);
      setRuns(allData.processedInstances);
      setHasMore(allData.processedInstances.length === 10);
      setTotalPages(Math.ceil(allData.response.data.total_count / 10));
    } catch (error) {
      console.error("Error fetching filtered instances:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleClosePopup(); // Close the popup and reset selection
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        (openStartDropdown &&
          startDropdownRef.current &&
          !startDropdownRef.current.contains(event.target)) ||
        (openEndDropdown &&
          endDropdownRef.current &&
          !endDropdownRef.current.contains(event.target)) ||
        (openStatusDropdown &&
          statusDropdownRef.current &&
          !statusDropdownRef.current.contains(event.target))
      ) {
        setOpenStartDropdown(false);
        setOpenEndDropdown(false);
        setOpenStatusDropdown(false); // Correctly use the setter function
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openStartDropdown, openEndDropdown, openStatusDropdown]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        (showStartCalendar &&
          startCalendarRef.current &&
          !startCalendarRef.current.contains(event.target)) ||
        (showEndCalendar &&
          endCalendarRef.current &&
          !endCalendarRef.current.contains(event.target))
      ) {
        setShowStartCalendar(false);
        setShowEndCalendar(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showStartCalendar, showEndCalendar]);

  const toggleExpand = (runId) => {
    setExpandedRunIds((prevIds) => {
      const newIds = new Set(prevIds);
      if (newIds.has(runId)) {
        newIds.delete(runId);
      } else {
        newIds.add(runId);
      }
      return newIds;
    });
  };

  const updateBreadcrumbs = async (thisWorkflowId, thisWorkflowName) => {
    const theseBreadcrumbs = sessionStorage.getItem("breadcrumbs")
      ? JSON.parse(sessionStorage.getItem("breadcrumbs"))
      : [];

    const breadcrumbExists = theseBreadcrumbs.some(
      (crumb) => crumb.id === thisWorkflowId
    );

    if (!breadcrumbExists) {
      setCurrentWorkflowId(thisWorkflowId);
      setCurrentWorkflowName(thisWorkflowName);

      const updatedBreadcrumbs = [
        ...theseBreadcrumbs,
        { id: thisWorkflowId, name: thisWorkflowName },
      ];
      sessionStorage.setItem("breadcrumbs", JSON.stringify(updatedBreadcrumbs));
      setBreadcrumbs(updatedBreadcrumbs);
    }
  };

  const handleWorkflowClick = async (thisWorkflowId, workflow) => {
    setHighlightedNodeId(null);
    setSelectedRunId(null);
    setIsDrawerOpen(false);
    setInitialHideDone(false);
    setWorkflowIsClicked(true);
    setRuns([]);
    setIsOverlayVisible(true);

    try {
      const token = await getAccessTokenSilently();
      const domain = getDomainFromEmail(user.email);
      const latestWorkflowInstanceId = await fetchLatestWorkflowInstance(
        thisWorkflowId,
        token,
        domain
      );

      if (latestWorkflowInstanceId && latestWorkflowInstanceId.id) {
        navigate(`/workflow/${thisWorkflowId}/${latestWorkflowInstanceId.id}`);
      } else {
        console.error("No valid instance ID returned");
        setErrorMessage("Unable to load workflow instance");
        setShowErrorPopup(true);
      }
    } catch (error) {
      console.error("Error fetching latest workflow instance:", error);
      setErrorMessage("Unable to load workflow. Please try again later.");
      setShowErrorPopup(true);
    } finally {
      setIsOverlayVisible(false);
    }
  };

  const handleBreadcrumbClick = async (thisWorkflowId, workflow) => {
    setWorkflowStructure(null);
    setHighlightedNodeId(null);
    setSelectedRunId(null);
    setIsDrawerOpen(false);
    setInitialHideDone(false);
    setWorkflowIsClicked(true);
    setIsOverlayVisible(true);
    setRuns([]);

    try {
      const token = await getAccessTokenSilently();
      const domain = getDomainFromEmail(user.email);
      const latestInstance = await fetchLatestWorkflowInstance(
        thisWorkflowId,
        token,
        domain
      );

      navigate(`/workflow/${thisWorkflowId}/${latestInstance.id}`);

      console.log(
        "Observable details in Breadcrumb Click: ",
        observableDetails
      );
    } catch (error) {
      console.error("Error handling breadcrumb click:", error);
    } finally {
      setIsOverlayVisible(false);
    }
  };

  const handleRunClick = async (event, run) => {
    event.preventDefault();
    setInitialHideDone(false);

    const token = await getAccessTokenSilently();
    const domain = getDomainFromEmail(user.email);
    setIsRunClick(true);

    if (
      event &&
      event.target &&
      event.target.closest(".see-more-button, .dropdown")
    ) {
      return;
    }

    setHighlightedNodeId(null);
    setLlmSummary("");
    setObservableSummary("");
    setIsOverlayVisible(true);
    setSelectedRunId(run.id);
    setSelectedNode(null);
    setShowEmptyDrawerPopup(true);

    navigate(`/workflow/${workflowId}/${run.id}`, {
      state: { name: run.name, description: run.description },
    });

    try {
      const structureData = await fetchWorkflowStructure(
        currentWorkflowId,
        run.id,
        token,
        domain
      );
      if (structureData) {
        setWorkflowStructure(structureData);
        const observableDetails = structureData.filter(
          (item) => item.type === "observable"
        );
        setObservableDetails(observableDetails);
        setHighlightedNodeId(structureData[0].id);
        setShowEmptyDrawerPopup(true);
      } else {
        console.warn(
          "Failed to fetch workflow structure for the selected run."
        );
      }
    } catch (error) {
      console.error("Error fetching workflow structure:", error);
    } finally {
      setIsOverlayVisible(false);
    }
  };

  const handleNodeClick = async (event, node) => {
    if (!node) {
      console.warn("Node is undefined:", node);
      return;
    }

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();

    if (selectedNode && selectedNode.id === node.id) {
      setSelectedNode(null);
      return;
    }

    setLlmSummary("");
    setObservableSummary("");
    setSelectedNode(node);
    setIsDrawerOpen(true);
    setIsLeftDrawerOpen(true);

    centerAndZoomOnNode(node);

    try {
      const token = await getAccessTokenSilently();
      if (node.type === "llm_call") {
        await fetchLlmCallSummary(
          node.id,
          (chunk) => {
            setLlmSummary((prev) => prev + chunk);
          },
          token,
          abortControllerRef.current
        );
      } else if (node.type === "observable") {
        await fetchObservableSummary(
          node.id,
          (chunk) => {
            setObservableSummary((prev) => prev + chunk);
          },
          token,
          abortControllerRef.current
        );
      }
    } catch (err) {
      if (err.name === "AbortError") {
        console.log(
          "Fetch request was aborted, which is expected when switching nodes quickly."
        );
      } else {
        console.error("Error fetching node summary:", err);
      }
    }
  };

  const centerAndZoomOnNode = (node) => {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    if (node && node.position) {
      const { x, y } = node.position;
      setCenter(x + viewportWidth / 5, y + viewportHeight / 8, {
        zoom: 1.3,
        duration: 800,
      });
    } else {
      setCenter(viewportWidth / 5, viewportHeight / 8, {
        zoom: 1.3,
        duration: 800,
      });
    }
  };

  useEffect(() => {
    const loadFullWorkflowData = async () => {
      if (!workflowId || !isFlowReady) return;

      if (isRunClick) {
        setIsRunClick(false);
        return;
      }

      try {
        setIsOverlayVisible(true);
        setWorkflowStructure(null);
        setHighlightedNodeId(null);
        setSelectedRunId(null);
        setIsDrawerOpen(false);
        setInitialHideDone(false);
        setCurrentWorkflowId(workflowId);
        setCurrentWorkflowName(currentWorkflowName);

        const token = await getAccessTokenSilently();
        const domain = getDomainFromEmail(user.email);

        let targetInstanceId = workflowInstanceId;
        if (!targetInstanceId) {
          const latestInstance = await fetchLatestWorkflowInstance(
            workflowId,
            token,
            domain
          );
          targetInstanceId = latestInstance.id;
        }

        await initializeData(workflowId, targetInstanceId, currentWorkflowName);
      } catch (error) {
        console.error("Error loading workflow data:", error);
      } finally {
        setIsOverlayVisible(false);
      }
    };

    loadFullWorkflowData();
  }, [workflowId, workflowInstanceId, isFlowReady]);

  const handleBackButtonClick = () => {
    sessionStorage.removeItem("breadcrumbs");
    setBreadcrumbs([]);
  };

  useEffect(() => {
    return () => {
      handleBackButtonClick();
    };
  }, []);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const toggleLeftDrawer = () => {
    setIsLeftDrawerOpen(!isLeftDrawerOpen);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowDown") {
        setFocusedIndex((prevIndex) => {
          let newIndex = prevIndex + 1;
          while (newIndex < observableDetails.length) {
            if (observableDetails[newIndex].type !== "root") break;
            newIndex++;
          }
          // Wrap around to the top if at the end
          return newIndex >= observableDetails.length ? 0 : newIndex;
        });
      } else if (event.key === "ArrowUp") {
        setFocusedIndex((prevIndex) => {
          let newIndex = prevIndex - 1;
          while (newIndex >= 0) {
            if (observableDetails[newIndex].type !== "root") break;
            newIndex--;
          }
          // Wrap around to the bottom if at the start
          return newIndex < 0 ? observableDetails.length - 1 : newIndex;
        });
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [observableDetails]);

  useEffect(() => {
    if (observableListRef.current[focusedIndex]) {
      observableListRef.current[focusedIndex].focus();
      handleNodeClick(null, observableDetails[focusedIndex]);
    }
  }, [focusedIndex]);

  useEffect(() => {
    if (lastClickedNodeIndex !== null) {
      setFocusedIndex(lastClickedNodeIndex);
    }
  }, [lastClickedNodeIndex]);

  const handleSeeMoreClickError = (errors) => {
    setErrorMessage(errors);
    setShowErrorPopup(true);
  };

  useEffect(() => {
    // Create ResizeObserver instance
    const resizeObserver = new ResizeObserver((entries) => {
      // Prevent the loop by wrapping in requestAnimationFrame
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        // Handle resize if needed
      });
    });

    // Observe the container
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    // Cleanup
    return () => {
      resizeObserver.disconnect();
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  return (
    <div className="WorkflowDetails" ref={containerRef}>
      <Header userIsQurrent={userIsQurrent} />
      <Breadcrumbs
        breadcrumbs={breadcrumbs}
        currentWorkflowId={currentWorkflowId}
        handleBackButtonClick={handleBackButtonClick}
        handleBreadcrumbClick={handleBreadcrumbClick}
      />
      <button className="drawer-toggle-button" onClick={toggleLeftDrawer}>
        <FontAwesomeIcon icon={faAnglesRight} />
      </button>
      <LeftDrawer
        toggleLeftDrawer={toggleLeftDrawer}
        handleNodeClick={handleNodeClick}
        formatNodeName={formatNodeName}
        isLeftDrawerOpen={isLeftDrawerOpen}
        workflowDescription={workflowDescription}
        observableDetails={observableDetails}
        selectedNode={selectedNode}
        observableListRef={observableListRef}
      />
      <div className="popup-drawer" onClick={toggleDrawer}>
        <FontAwesomeIcon icon={faAnglesLeft} className="right-angles" />
      </div>
      <div className={`drawer ${isDrawerOpen ? "open" : ""}`} ref={drawerRef}>
        {selectedNode ? (
          <div>
            {(() => {
              switch (selectedNode.type) {
                case "action":
                  return (
                    <ActionNodePopup
                      handleClosePopup={handleClosePopup}
                      handleIdClick={handleIdClick}
                      formatId={formatId}
                      formatDate={formatDate}
                      formatContent={formatContent}
                      node={selectedNode}
                      copied={copied}
                    />
                  );
                case "llm_call":
                  return (
                    <LLMCallNodePopup
                      handleClosePopup={handleClosePopup}
                      handleIdClick={handleIdClick}
                      formatId={formatId}
                      formatDate={formatDate}
                      formatContent={formatContent}
                      node={selectedNode}
                      copied={copied}
                      llmSummary={llmSummary}
                    />
                  );
                case "observable":
                  return (
                    <ObservableNodePopup
                      handleClosePopup={handleClosePopup}
                      handleIdClick={handleIdClick}
                      formatId={formatId}
                      formatDate={formatDate}
                      formatContent={formatContent}
                      node={selectedNode}
                      copied={copied}
                      observableSummary={observableSummary}
                    />
                  );
                case "agent":
                  return (
                    <AgentNodePopup
                      handleClosePopup={handleClosePopup}
                      handleIdClick={handleIdClick}
                      formatId={formatId}
                      formatDate={formatDate}
                      formatContent={formatContent}
                      node={selectedNode}
                      copied={copied}
                    />
                  );
                default:
                  return (
                    <AgentNodePopup
                      handleClosePopup={handleClosePopup}
                      handleIdClick={handleIdClick}
                      formatId={formatId}
                      formatDate={formatDate}
                      formatContent={formatContent}
                      node={selectedNode}
                      copied={copied}
                    />
                  );
              }
            })()}
          </div>
        ) : (
          <div>
            {(!selectedNode || showEmptyDrawerPopup) && (
              <EmptyDrawerMessagePopup
                currentWorkflowName={currentWorkflowName}
                workflowDescription={workflowDescription}
                onClose={handleClosePopup}
                outputs={selectedRunOutput}
                workflowId={currentWorkflowId}
                workflowCreatedAt={workflowCreatedAt}
                copied={copied}
                handleIdClick={handleIdClick}
              />
            )}
          </div>
        )}
      </div>
      {renderWorkflowStructure()}
      <WorkflowTable
        handleSortClick={handleSortClick}
        getSortIcon={getSortIcon}
        handleFilterToggle={handleFilterToggle}
        toggleFilter={toggleFilter}
        removeFilter={removeFilter}
        toggleDropdown={toggleDropdown}
        setRunStartFilter={setRunStartFilter}
        setRunStartDisplay={setRunStartDisplay}
        setRunEndFilter={setRunEndFilter}
        setRunEndDisplay={setRunEndDisplay}
        setActiveFilters={setActiveFilters}
        handleCustomDateSelection={handleCustomDateSelection}
        setShowCustomCalendar={setShowCustomCalendar}
        handleWorkflowClick={handleWorkflowClick}
        handleRunClick={handleRunClick}
        toggleExpand={toggleExpand}
        getStatusIcon={getStatusIcon}
        handlePageChange={handlePageChange}
        combinedRuns={combinedRuns}
        runStartDisplay={runStartDisplay}
        runEndDisplay={runEndDisplay}
        openStartDropdown={openStartDropdown}
        openEndDropdown={openEndDropdown}
        openStatusDropdown={openStatusDropdown}
        activeFilters={activeFilters}
        runStartFilter={runStartFilter}
        runEndFilter={runEndFilter}
        total={total}
        isLoading={isLoading}
        isDrawerOpen={isDrawerOpen}
        drawerRef={drawerRef}
        showCustomCalendar={showCustomCalendar}
        dateRange={dateRange}
        statusDropdownRef={statusDropdownRef}
        startDropdownRef={startDropdownRef}
        endDropdownRef={endDropdownRef}
        selectedRunId={selectedRunId}
        childWorkflows={childWorkflows}
        expandedRunIds={expandedRunIds}
        totalPages={totalPages}
        currentPage={currentPage}
        handleSeeMoreClickError={handleSeeMoreClickError}
      />
      {showPopup && (
        <OutputPopup
          outputs={popupContent}
          onClose={() => setShowPopup(false)}
        />
      )}
      {showErrorPopup && (
        <ErrorPopup
          errorMessage={errorMessage}
          onClose={() => setShowErrorPopup(false)}
        />
      )}
    </div>
  );
}

export default WorkflowDetails;
