import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import config from "./config.js";

const Auth0Auth = () => {
  const { loginWithRedirect, logout } = useAuth0();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get("error")) {
      setErrorMessage(params.get("error_description") || "An error occurred.");
    }
  }, []);
  const handleLogin = () => {
    loginWithRedirect({
      redirectUri: `${window.location.origin}`,
    });
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="company-logo" aria-label="Company Logo"></div>
        <h1 className="welcome-message">Welcome to Qurrent!</h1>
        {errorMessage && (
          <div className="error-banner">
            <p>{errorMessage}</p>
            <button
              onClick={() => {
                const auth0Domain = config.auth0Domain;
                logout({
                  returnTo: window.location.origin,
                  federated: true,
                  end_session_endpoint: `https://${auth0Domain}/v2/logout?federated`,
                });
              }}
              className="logout-button"
            >
              Log Out
            </button>
          </div>
        )}
        <p className="login-instruction">
          Please sign in with your Qurrent account
        </p>
        <button onClick={handleLogin} className="login-button">
          Log In
        </button>
      </div>
    </div>
  );
};

export default Auth0Auth;
