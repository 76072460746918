import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { EnvironmentContext } from "../contexts/EnvironmentContext.js";
import { getConfig } from "../config.js";
import { formatNodeName, formatDate, mapStatus } from "./formatUtils.js";
import { useNavigate } from "react-router-dom";

// Custom hook to use API utilities with environment context
export const useApiUtils = () => {
  const { env } = useContext(EnvironmentContext);
  const [config, setConfig] = useState(getConfig(env));
  const navigate = useNavigate();

  useEffect(() => {
    setConfig(getConfig(env));
  }, [env]);

  const fetchWorkflowInstances = async ({
    token,
    domain,
    column,
    direction,
    activeFilters,
    showPreviousVersions,
    isSuperdupont,
    selectedCustomer,
    page,
  }) => {
    console.log("activeFilters", activeFilters);
    const apiUrl = config.apiOrigin;
    const response = await axios.get(`${apiUrl}/filter/workflows/grouped`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      params: {
        domain: domain,
        sort_by: column,
        sort_order: direction,
        status: activeFilters.status
          ? activeFilters.status.toLowerCase()
          : undefined,
        search: activeFilters.name || undefined,
        start_date: activeFilters.lastRun
          ? activeFilters.lastRun.start
          : undefined,
        end_date: activeFilters.lastRun ? activeFilters.lastRun.end : undefined,
        latest_versions_only: !showPreviousVersions,
        page: page,
        limit: 20,
        is_superdupont: isSuperdupont,
        customer: selectedCustomer || undefined,
      },
    });

    return response.data;
  };

  const fetchCustomers = async (token) => {
    try {
      const apiUrl = config.apiOrigin;
      const response = await axios.get(`${apiUrl}/customers`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };

  const searchWorkflows = async (searchString, workflows, token) => {
    console.log("Type of workflows:", typeof workflows);
    console.log("WORKFLOWS: ", workflows);
    const apiUrl = config.apiOrigin;

    try {
      await axios.post(
        `${apiUrl}/workflows/search`,
        {
          search_string: searchString,
          workflows: workflows,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
    } catch (error) {
      console.error("Error searching workflows:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        console.error("Request data:", error.request);
      } else {
        console.error("Error message:", error.message);
      }
    }
  };

  const fetchFilteredInstances = async (
    currentWorkflowId,
    instanceId,
    theseNewPage,
    theseNewFilters,
    theseNewSortColumn,
    theseNewSortDirection,
    token,
    domain
  ) => {
    try {
      const params = {
        status: theseNewFilters.status,
        run_start: theseNewFilters.run_start,
        run_end: theseNewFilters.run_end,
        sort_by: theseNewSortColumn,
        sort_order: theseNewSortDirection,
        page: theseNewPage,
        limit: 10,
        current_instance_id: instanceId,
        domain: domain,
      };

      const apiUrl = config.apiOrigin;
      const response = await axios.get(
        `${apiUrl}/workflows/${currentWorkflowId}/instances/filter`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          params: params,
        }
      );

      if (response.status !== 200) {
        throw new Error("Failed to fetch filtered instances");
      }

      const data = response.data.filtered_instances || [];

      const processedInstances = data.map((instance) => {
        const startTime = instance.start_time
          ? new Date(instance.start_time)
          : null;
        const endTime = instance.end_time ? new Date(instance.end_time) : null;

        return {
          id: instance.id,
          startTime,
          endTime,
          formattedStartTime: startTime ? formatDate(startTime) : "N/A",
          formattedEndTime: endTime ? formatDate(endTime) : "N/A",
          status: mapStatus(instance.status),
          outputDetails: `Actions: ${instance.action_count}, LLM Calls: ${instance.llm_call_count}`,
          output: instance.output || "N/A",
          error: instance.error || "N/A",
        };
      });

      return { response, processedInstances };
    } catch (error) {
      console.error("Error fetching filtered instances:", error);
      if (error.response && error.response.status === 403) {
        navigate("/not-authorized");
      }
      return [];
    }
  };

  const fetchWorkflowStructure = async (
    workflowId,
    instanceId,
    token,
    domain
  ) => {
    try {
      const apiUrl = config.apiOrigin;
      const response = await axios.get(
        `${apiUrl}/workflows/${workflowId}/instances/${instanceId}/structure`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          params: {
            domain: domain,
          },
        }
      );

      if (response.status !== 200) {
        throw new Error("Network response was not ok");
      }

      return response.data.map((node) => ({
        ...node,
        function_name: formatNodeName(node.function_name),
      }));
    } catch (error) {
      console.warn("Error fetching workflow structure:", error);
      return null;
    }
  };

  const fetchChildWorkflows = async (
    theseWorkflowIds,
    currentCustomerId,
    token,
    domain
  ) => {
    try {
      const apiUrl = config.apiOrigin;
      const response = await axios.post(
        `${apiUrl}/workflows/children`,
        {
          workflow_id: theseWorkflowIds,
          customer_id: currentCustomerId,
          domain: domain,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      const processedChildWorkflows = {};
      Object.entries(response.data).forEach(([parentId, children]) => {
        processedChildWorkflows[parentId] = children.map((child) => {
          const startTime = child.created_at
            ? new Date(child.created_at)
            : null;
          const endTime = child.end_time ? new Date(child.end_time) : null;

          return {
            id: child.id,
            name: child.name,
            workflow_id: child.workflow_id,
            startTime,
            endTime,
            formattedStartTime: startTime ? formatDate(startTime) : "N/A",
            formattedEndTime: endTime ? formatDate(endTime) : "N/A",
            status: mapStatus(child.status),
            outputDetails: `Actions: ${child.action_count}, LLM Calls: ${child.llm_call_count}`,
            output: child.output || "N/A",
            error: child.error || "N/A",
            parent_workflow_instance_id: child.parent_workflow_instance_id,
          };
        });
      });

      return processedChildWorkflows;
    } catch (error) {
      console.error("Error fetching child workflows:", error);
      return {};
    }
  };

  const fetchObservableSummary = async (
    observableId,
    onChunkReceived,
    token,
    abortController
  ) => {
    const apiUrl = config.apiOrigin;
    const response = await fetch(
      `${apiUrl}/observable_summary/${observableId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        signal: abortController.signal,
      }
    );

    if (!response.ok) {
      throw new Error("Failed to fetch summary");
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder("utf-8");
    let done = false;

    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading;
      const chunk = decoder.decode(value, { stream: true });
      onChunkReceived(chunk);
    }
  };

  const fetchLlmCallSummary = async (
    nodeId,
    onChunkReceived,
    token,
    abortController
  ) => {
    const apiUrl = config.apiOrigin;
    const response = await fetch(`${apiUrl}/llm_call_summary/${nodeId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      signal: abortController.signal,
    });

    if (!response.ok) {
      throw new Error("Failed to fetch summary");
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder("utf-8");
    let done = false;

    while (!done) {
      const { value, done: doneReading } = await reader.read();
      done = doneReading;
      const chunk = decoder.decode(value, { stream: true });
      onChunkReceived(chunk);
    }
  };

  const fetchLatestWorkflowInstance = async (workflowId, token, domain) => {
    const apiUrl = config.apiOrigin;
    const response = await axios.get(`${apiUrl}/workflow/latest_instance`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      params: {
        workflow_id: workflowId,
        domain: domain,
      },
    });

    if (!response.data) {
      throw new Error("No workflow instance found for the given workflow ID");
    }

    return response.data;
  };

  const fetchTotalRuns = async (domain, isSuperdupont, token) => {
    try {
      const apiUrl = config.apiOrigin;
      const response = await axios.get(`${apiUrl}/total_runs`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        params: {
          domain: domain,
          is_superdupont: isSuperdupont,
        },
      });

      if (response.data && typeof response.data.total_runs === "number") {
        return response.data.total_runs;
      } else {
        console.error("Unexpected response format:", response.data);
        return null;
      }
    } catch (error) {
      console.error("Error fetching total runs:", error);
      return null;
    }
  };

  return {
    fetchWorkflowInstances,
    fetchCustomers,
    searchWorkflows,
    fetchFilteredInstances,
    fetchWorkflowStructure,
    fetchChildWorkflows,
    fetchObservableSummary,
    fetchLlmCallSummary,
    fetchLatestWorkflowInstance,
    fetchTotalRuns,
  };
};
