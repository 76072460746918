// src/components/Table.js
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

function MainTable({
  workflows,
  handleSortClick,
  getSortIcon,
  handleRowClick,
  previousVersions,
  formatDateTime,
  showPreviousVersions = false,
  handleCheckboxChange,
}) {
  return (
    <div className="App-table">
      <div className="App-table-header">
        <div className="App-table-row">
          <div
            className="App-table-cell"
            onClick={() => handleSortClick("latest_status")}
          >
            Last Status {getSortIcon("latest_status")}
          </div>
          <div
            className="App-table-cell"
            onClick={() => handleSortClick("runs")}
          >
            Runs {getSortIcon("runs")}
          </div>
          <div
            className="App-table-cell"
            onClick={() => handleSortClick("failed")}
          >
            Failed {getSortIcon("failed")}
          </div>
          <div
            className="App-table-cell"
            onClick={() => handleSortClick("workflow_name")}
          >
            Name {getSortIcon("workflow_name")}
          </div>
          <div className="App-table-cell">
            Version
            <input
              type="checkbox"
              checked={!!showPreviousVersions}
              onChange={handleCheckboxChange}
              className="tiny-checkbox"
            />
          </div>
          <div className="App-table-cell description-header">Description</div>
          <div
            className="App-table-cell"
            onClick={() => handleSortClick("lastRun")}
          >
            Last Run {getSortIcon("lastRun")}
          </div>
        </div>
      </div>
      <div className="App-table-body scrollable">
        {workflows.map((item, index) => (
          <div
            className={`App-table-row grid-row ${
              previousVersions.has(item.id) ? "previous-version" : ""
            }`}
            key={index}
            onClick={() => handleRowClick(item)}
          >
            <div className="App-table-cell">
              {item.warnings ? (
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="status-icon-warning"
                />
              ) : item.status === "completed" ? (
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  className="status-icon-success"
                />
              ) : item.status === "failed" ? (
                <FontAwesomeIcon
                  icon={faCircleXmark}
                  className="status-icon-failed"
                />
              ) : item.status === "created" ? (
                <span className="status-icon created">—</span>
              ) : null}
            </div>
            <div className="App-table-cell">{item.runs}</div>
            <div className="App-table-cell">{item.failed}</div>
            <div className="App-table-cell">{item.name}</div>
            <div className="App-table-cell">{item.version}</div>
            <div className="App-table-cell">
              <div className="tooltip-container-description">
                {item.description ||
                  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit."}
                <span className="tooltip-text-description">
                  {item.description ||
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit."}
                </span>
              </div>
            </div>
            <div className="App-table-cell">
              <div className="tooltip-container-date">
                {formatDateTime(item.last_run).displayText}
                <span className="tooltip-text-date">
                  {formatDateTime(item.last_run).fullFormattedDate}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MainTable;
