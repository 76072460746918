import React from "react";
import "../App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone } from "@fortawesome/free-solid-svg-icons";
import { formatId } from "../utils/formatUtils.js";

const EmptyDrawerMessagePopup = ({
  currentWorkflowName,
  workflowDescription,
  onClose,
  outputs,
  workflowId,
  workflowCreatedAt,
  copied,
  handleIdClick,
}) => {
  const renderOutputs = (outputs) => {
    if (Array.isArray(outputs) && outputs.length > 0) {
      const uniqueOutputs = [...new Set(outputs)];
      return uniqueOutputs.map((output, index) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return (
          <React.Fragment key={index}>
            <p>
              {output.split(urlRegex).map((part, i) => {
                if (part.match(urlRegex)) {
                  return (
                    <a
                      key={i}
                      href={part}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {part}
                    </a>
                  );
                }
                return part;
              })}
            </p>
            {index < uniqueOutputs.length - 1 && (
              <hr className="divider-popup" />
            )}
          </React.Fragment>
        );
      });
    }
    return <p>No output available</p>;
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className="main-row">
          <div className="title-row">
            <div className="title-header">
              {currentWorkflowName || "Workflow"}
            </div>
            <button className="close-button" onClick={onClose}>
              &times;
            </button>
          </div>
        </div>
        <div className="description-text-content">
          {workflowDescription || "No description available"}
        </div>
        <hr className="divider-popup" />
        <div>
          <p className="key-value-pair">
            <strong>ID:</strong>
            <span
              className="id-tooltip"
              title={workflowId}
              onClick={() => handleIdClick(workflowId)}
              style={{ cursor: "pointer" }}
            >
              <FontAwesomeIcon className="copy-icon" icon={faClone} />

              {formatId(workflowId)}
              <span
                onClick={() => handleIdClick(workflowId)}
                style={{
                  cursor: "pointer",
                  marginLeft: "5px",
                  position: "relative",
                }}
              >
                {copied && <span className="copied-style">Copied!</span>}
              </span>
            </span>
          </p>
          <p className="key-value-pair">
            <strong>START:</strong>
            <span>{workflowCreatedAt}</span>
          </p>
        </div>
        <hr className="divider-popup" />
        <div>
          <strong className="description-content">OUTPUT</strong>
          <div className="formatted-content">{renderOutputs(outputs)}</div>
        </div>
      </div>
    </div>
  );
};

export default EmptyDrawerMessagePopup;
