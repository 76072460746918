import React, { createContext, useContext, useState } from "react";

// Create a context for Superdupont
const SuperdupontContext = createContext();

// Create a provider component
export const SuperdupontProvider = ({ children }) => {
  const [isSuperdupont, setIsSuperdupont] = useState(() => {
    const savedMode = sessionStorage.getItem("superdupontMode");
    return savedMode ? JSON.parse(savedMode) : false;
  });

  const toggleSuperdupont = () => {
    setIsSuperdupont((prev) => {
      const newState = !prev;
      sessionStorage.setItem("superdupontMode", JSON.stringify(newState));
      return newState;
    });
  };

  return (
    <SuperdupontContext.Provider value={{ isSuperdupont, toggleSuperdupont }}>
      {children}
    </SuperdupontContext.Provider>
  );
};

// Custom hook to use the Superdupont context
export const useSuperdupont = () => {
  return useContext(SuperdupontContext);
};
