// supervise-console/src/WorkflowTable.js
import React, { useCallback, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Calendar from "react-calendar";
import WorkflowRow from "./WorkflowRow.js";
import NodeKey from "./NodeKey.js"; // Import the NodeKey component

const statusDisplayMap = {
  failed: "Failed",
  completed: "Success",
  created: "No Status",
  error: "Warning",
};

function WorkflowTable({
  combinedRuns,
  handleSortClick,
  getSortIcon,
  runStartDisplay,
  runEndDisplay,
  toggleDropdown,
  openStartDropdown,
  openEndDropdown,
  openStatusDropdown,
  handleFilterToggle,
  toggleFilter,
  removeFilter,
  activeFilters,
  runStartFilter,
  runEndFilter,
  setRunStartFilter,
  setRunStartDisplay,
  setRunEndFilter,
  setRunEndDisplay,
  total,
  isLoading,
  setActiveFilters,
  isDrawerOpen,
  drawerRef,
  showCustomCalendar,
  handleCustomDateSelection,
  dateRange,
  setShowCustomCalendar,
  statusDropdownRef,
  startDropdownRef,
  endDropdownRef,
  selectedRunId,
  childWorkflows,
  handleWorkflowClick,
  handleRunClick,
  toggleExpand,
  getStatusIcon,
  expandedRunIds,
  handlePageChange,
  currentPage,
  totalPages,
  loading,
  handleSeeMoreClickError,
}) {
  const observer = useRef();

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return; // Do not observe while loading

      if (observer.current) observer.current.disconnect(); // Disconnect the previous observer

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && currentPage < totalPages) {
            handlePageChange(currentPage + 1);
          }
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 0.1,
        }
      );

      if (node) observer.current.observe(node);
    },
    [loading, currentPage, totalPages]
  );

  return (
    <div
      className={`fixed-bottom ${isDrawerOpen ? "open" : ""}`}
      ref={drawerRef}
    >
      {showCustomCalendar && (
        <>
          <div className="App-date-range-picker">
            <Calendar
              onChange={handleCustomDateSelection}
              value={dateRange}
              selectRange={true}
              className="custom-calendar"
            />
            <button
              className="App-submit-date-range"
              onClick={() => setShowCustomCalendar(false)}
            >
              Close
            </button>
          </div>
        </>
      )}
      <div className="App-filter-row-workflows">
        <div className="App-filter-dropdown" ref={statusDropdownRef}>
          <button
            className={`App-filter-button ${
              activeFilters.status ? "active" : ""
            }`}
            onClick={() => toggleDropdown("status")}
          >
            {activeFilters.status
              ? statusDisplayMap[activeFilters.status]
              : "Status"}{" "}
            <FontAwesomeIcon icon={faChevronDown} className="small-chevron" />
          </button>
          {activeFilters.status && (
            <button
              className="App-filter-clear"
              onClick={() => {
                setActiveFilters((prev) => ({ ...prev, status: null }));
                toggleFilter(null, runStartFilter, runEndFilter);
                removeFilter(0);
              }}
            >
              ×
            </button>
          )}
          {openStatusDropdown && (
            <div className="App-filter-options options-style-status">
              <div
                className="dropdown-option"
                onClick={() => handleFilterToggle("status", "failed")}
              >
                Failed
              </div>
              <div
                className="dropdown-option"
                onClick={() => handleFilterToggle("status", "error")}
              >
                Warning
              </div>
              <div
                className="dropdown-option"
                onClick={() => handleFilterToggle("status", "completed")}
              >
                Success
              </div>
              <div
                className="dropdown-option"
                onClick={() => handleFilterToggle("status", "created")}
              >
                No Status
              </div>
            </div>
          )}
        </div>
        <div className="App-filter-dropdown" ref={startDropdownRef}>
          <button
            className={`App-filter-button ${runStartDisplay ? "active" : ""}`}
            onClick={() => toggleDropdown("runStart")}
          >
            {runStartDisplay || "Run Start"}{" "}
            <FontAwesomeIcon icon={faChevronDown} className="small-chevron" />
          </button>
          {runStartDisplay && (
            <button
              className="App-filter-clear"
              onClick={() => {
                setRunStartFilter([null, null]);
                setRunStartDisplay(null);
                toggleFilter(activeFilters.status, null, runEndFilter);
                removeFilter(1);
              }}
            >
              ×
            </button>
          )}
          {openStartDropdown && (
            <div className="App-filter-options options-style-runs">
              <div
                className="dropdown-option"
                onClick={() => handleFilterToggle("runStart", "Last 7 days")}
              >
                Last 7 days
              </div>
              <div
                className="dropdown-option"
                onClick={() => handleFilterToggle("runStart", "Last 30 days")}
              >
                Last 30 days
              </div>
              <div
                className="dropdown-option"
                onClick={() => handleFilterToggle("runStart", "Custom")}
              >
                Custom date range
              </div>
            </div>
          )}
        </div>
        <div className="App-filter-dropdown" ref={endDropdownRef}>
          <button
            className={`App-filter-button ${runEndDisplay ? "active" : ""}`}
            onClick={() => toggleDropdown("runEnd")}
          >
            {runEndDisplay || "Run End"}{" "}
            <FontAwesomeIcon icon={faChevronDown} className="small-chevron" />
          </button>
          {runEndDisplay && (
            <button
              className="App-filter-clear"
              onClick={() => {
                setRunEndFilter([null, null]);
                setRunEndDisplay(null);
                toggleFilter(activeFilters.status, runStartFilter, null);
                removeFilter(2);
              }}
            >
              ×
            </button>
          )}
          {openEndDropdown && (
            <div className="App-filter-options options-style-runs">
              <div
                className="dropdown-option"
                onClick={() => handleFilterToggle("runEnd", "Last 7 days")}
              >
                Last 7 days
              </div>
              <div
                className="dropdown-option"
                onClick={() => handleFilterToggle("runEnd", "Last 30 days")}
              >
                Last 30 days
              </div>
              <div
                className="dropdown-option"
                onClick={() => handleFilterToggle("runEnd", "Custom")}
              >
                Custom date range
              </div>
            </div>
          )}
        </div>
        <div className="number-of-runs">
          <strong>{total}</strong> {total === 1 ? "run" : "runs"}
        </div>{" "}
        {isLoading && <div className="loading-spinner-workflow"></div>}
        <div className="node-key-container">
          <NodeKey />
        </div>
      </div>

      <div className="table-body-container">
        <table className="workflow-table">
          <thead>
            <tr>
              <th></th>
              <th
                className="sortable"
                onClick={() => handleSortClick("startTime")}
              >
                Run Start {getSortIcon("startTime")}
              </th>
              <th
                className="sortable"
                onClick={() => handleSortClick("endTime")}
              >
                Run End {getSortIcon("endTime")}
              </th>
              <th
                className="sortable"
                onClick={() => handleSortClick("status")}
              >
                Status {getSortIcon("status")}
              </th>
              <th className="details-cell">Details</th>
              <th>Errors</th>
            </tr>
          </thead>
          <tbody>
            {combinedRuns.map((workflow, index) => (
              <WorkflowRow
                key={workflow.id}
                workflow={workflow}
                isLast={index === combinedRuns.length - 1}
                selectedRunId={selectedRunId}
                childWorkflows={childWorkflows}
                handleWorkflowClick={handleWorkflowClick}
                handleRunClick={handleRunClick}
                toggleExpand={toggleExpand}
                getStatusIcon={getStatusIcon}
                expandedRunIds={expandedRunIds}
                lastElementRef={
                  index === combinedRuns.length - 1 ? lastElementRef : null
                }
                handleSeeMoreClickError={handleSeeMoreClickError}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default WorkflowTable;
