import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import config from "./config.js";

const AutoLogout = () => {
  const { logout } = useAuth0();
  const INACTIVITY_LIMIT = 28800000; // 8 hours in milliseconds
  const INACTIVITY_WARNING = 5000; // 5 seconds in milliseconds

  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    let timeoutId;
    let warningTimeoutId;

    const resetTimeout = () => {
      if (timeoutId) clearTimeout(timeoutId);
      if (warningTimeoutId) clearTimeout(warningTimeoutId);

      // Set warning timeout
      warningTimeoutId = setTimeout(() => {
        console.log("Showing warning"); // Debug log
        setShowWarning(true);
      }, INACTIVITY_LIMIT - INACTIVITY_WARNING);

      // Set logout timeout
      timeoutId = setTimeout(() => {
        console.log("Logging out due to inactivity"); // Debug log
        const auth0Domain = config.auth0Domain;
        logout({
          returnTo: window.location.origin,
          federated: true,
          end_session_endpoint: `https://${auth0Domain}/v2/logout?federated`,
        });
      }, INACTIVITY_LIMIT);
    };

    const handleActivity = () => {
      // console.log("Activity detected, resetting timeout"); // Debug log
      setShowWarning(false); // Hide warning on activity
      resetTimeout();
    };

    // List of events to consider as user activity
    const activityEvents = [
      "mousemove",
      "mousedown",
      "keypress",
      "scroll",
      "touchstart",
    ];

    // Add event listeners for user activity
    activityEvents.forEach((event) =>
      window.addEventListener(event, handleActivity)
    );

    // Initialize the timeout
    resetTimeout();

    // Cleanup event listeners on component unmount
    return () => {
      clearTimeout(timeoutId);
      clearTimeout(warningTimeoutId);
      activityEvents.forEach((event) =>
        window.removeEventListener(event, handleActivity)
      );
    };
  }, [logout]);

  return (
    <>
      {showWarning && (
        <div className="inactivity-warning">Logging out due to inactivity.</div>
      )}
    </>
  );
};

export default AutoLogout;
