import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRobot,
  faPenToSquare,
  faGear,
  faComments,
} from "@fortawesome/free-solid-svg-icons";

function NodeKey() {
  return (
    <div className="node-key">
      <div className="node-key-item">
        <FontAwesomeIcon icon={faRobot} className="fa-icon" /> Agent
      </div>
      <div className="node-key-item">
        <FontAwesomeIcon icon={faPenToSquare} className="fa-icon" /> Task
      </div>
      <div className="node-key-item">
        <FontAwesomeIcon icon={faGear} className="fa-icon" /> Action
      </div>
      <div className="node-key-item">
        <FontAwesomeIcon icon={faComments} className="fa-icon" /> LLM Call
      </div>
    </div>
  );
}

export default NodeKey;
