import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Calendar from "react-calendar";

function FilterRow({
  activeFilters,
  toggleFilterOptions,
  handleStatusFilter,
  setActiveFilters,
  openDropdown,
  handleNameKeyPress,
  handleSearch,
  handleDateFilter,
  formatDateRange,
  customers,
  filterWorkflowsByCustomer,
  selectedCustomer,
  setSelectedCustomer,
  showCalendar,
  calendarRef,
  handleChange,
  selectedDateRange,
  submitDateRange,
  submitButtonRef,
  isSuperdupont,
}) {
  return (
    <div className="App-filter-row">
      <div className="App-filter-dropdown">
        <button
          className={`App-filter-button ${
            activeFilters.status ? "active" : ""
          }`}
          onClick={() => toggleFilterOptions("status")}
        >
          {activeFilters.status
            ? activeFilters.status === "failed"
              ? "Failed"
              : activeFilters.status === "error"
              ? "Warning"
              : activeFilters.status === "completed"
              ? "Success"
              : activeFilters.status === "created"
              ? "No Status"
              : activeFilters.status
            : "Last Status"}{" "}
          <FontAwesomeIcon
            icon={faChevronDown}
            className="small-chevron"
            style={{ marginLeft: "5px" }}
          />
        </button>
        {activeFilters.status && (
          <button
            className="App-filter-clear"
            onClick={() => {
              setActiveFilters((prev) => ({ ...prev, status: null }));
            }}
          >
            ×
          </button>
        )}
        {openDropdown === "status" && (
          <div className="App-filter-options">
            <div
              className="dropdown-option"
              onClick={() => handleStatusFilter("failed")}
            >
              Failed
            </div>
            <div
              className="dropdown-option"
              onClick={() => handleStatusFilter("error")}
            >
              Warning
            </div>
            <div
              className="dropdown-option"
              onClick={() => handleStatusFilter("completed")}
            >
              Success
            </div>
            <div
              className="dropdown-option"
              onClick={() => handleStatusFilter("created")}
            >
              No Status
            </div>
          </div>
        )}
      </div>
      <div className="App-filter-dropdown">
        <button
          className={`App-filter-button ${activeFilters.name ? "active" : ""}`}
          onClick={() => toggleFilterOptions("name")}
        >
          {activeFilters.name || "Name"}{" "}
          <FontAwesomeIcon
            icon={faChevronDown}
            className="small-chevron"
            style={{ marginLeft: "5px" }}
          />
        </button>
        {activeFilters.name && (
          <button
            className="App-filter-clear"
            onClick={() => {
              setActiveFilters((prev) => ({ ...prev, name: null }));
            }}
          >
            ×
          </button>
        )}
        {openDropdown === "name" && (
          <div
            className="App-filter-options"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="search-container">
              <input
                type="text"
                placeholder="Search by name..."
                onChange={(e) => {
                  const searchTerm = e.target.value.trim();
                  setActiveFilters((prev) => ({ ...prev, name: searchTerm }));
                }}
                onKeyDown={handleNameKeyPress}
                className="App-search-input styled-search-input"
              />
              <button
                className="App-search-submit"
                onClick={() => {
                  const searchTerm = activeFilters.name;
                  handleSearch(searchTerm);
                }}
              >
                ✓
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="App-filter-dropdown">
        <button
          className={`App-filter-button ${
            activeFilters.lastRun ? "active" : ""
          }`}
          onClick={() => toggleFilterOptions("lastRun")}
        >
          {typeof activeFilters.lastRun === "string"
            ? activeFilters.lastRun
            : formatDateRange(activeFilters.lastRun)}{" "}
          <FontAwesomeIcon
            icon={faChevronDown}
            className="small-chevron"
            style={{ marginLeft: "5px" }}
          />
        </button>
        {activeFilters.lastRun && (
          <button
            className="App-filter-clear"
            onClick={() => {
              setActiveFilters((prev) => ({ ...prev, lastRun: null }));
            }}
          >
            ×
          </button>
        )}
        {openDropdown === "lastRun" && (
          <div className="App-filter-options">
            <div
              className="dropdown-option"
              onClick={() => handleDateFilter("Last 7 Days")}
            >
              Last 7 Days
            </div>
            <div
              className="dropdown-option"
              onClick={() => handleDateFilter("Last 30 Days")}
            >
              Last 30 Days
            </div>
            <div
              className="dropdown-option"
              onClick={() => handleDateFilter("Custom")}
            >
              Custom date range
            </div>
          </div>
        )}
      </div>
      {isSuperdupont && (
        <div className="App-filter-row-customer-env">
          <div className="App-filter-dropdown">
            <div className="vertical-divider">|</div>

            <button
              className={`App-filter-button ${
                selectedCustomer ? "active" : ""
              }`}
              onClick={() => toggleFilterOptions("customer")}
            >
              {selectedCustomer || "Customer"}{" "}
              <FontAwesomeIcon
                icon={faChevronDown}
                className="small-chevron"
                style={{ marginLeft: "5px" }}
              />
            </button>
            {selectedCustomer && (
              <button
                className="App-filter-clear"
                onClick={() => {
                  setSelectedCustomer(null);
                }}
              >
                ×
              </button>
            )}
            {openDropdown === "customer" && (
              <div
                className="App-filter-options options-style-customer"
                onClick={(e) => e.stopPropagation()}
              >
                <div
                  className="dropdown-option"
                  onClick={() => filterWorkflowsByCustomer("")}
                >
                  All Customers
                </div>
                {customers.map((customer) => (
                  <div
                    key={customer.id}
                    className="dropdown-option"
                    onClick={() => filterWorkflowsByCustomer(customer.name)}
                  >
                    {customer.name}
                  </div>
                ))}
              </div>
            )}
          </div>
          {/* <EnvSwitcher onEnvironmentSwitch={fetchWorkflowInstances} /> */}
        </div>
      )}
      {showCalendar && (
        <div ref={calendarRef} className="App-date-range-picker-main">
          <Calendar
            onChange={handleChange}
            value={selectedDateRange}
            selectRange={true}
            className="custom-calendar-main custom-calendar"
          />
          <button
            ref={submitButtonRef}
            className="App-submit-date-range-main"
            onClick={submitDateRange}
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
}

export default FilterRow;
